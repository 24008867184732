import http from '../../../services/http';

export default class Voucher {
  static fetchBillingHistories(params) {
    return http.get('/UserBillingHistories/all', {
      params
    });
  }

  static fetchListCharges(params) {
    return http.get('/UserBillings/list-charge', {
      params
    });
  }

  static getUserBillingInfo(userId) {
    return http.get(`/UserBillings/${userId}`);
  }

  static updateBilling(body) {
    return http.post('/UserBillings/create-or-update', body);
  }

  static addBillingAmount(userId, body) {
    return http.patch(`/UserBillings/add-amount/${userId}`, body);
  }

  static setCardInvalid(userId, body) {
    return http.patch(`/UserBillings/set-user-card-invalid/${userId}`, body);
  }

  static setApproveCharge(userId) {
    return http.post(`/UserBillings/approve-payment/${userId}`);
  }
}
