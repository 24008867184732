import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import jwtDecode from 'jwt-decode';
import { get } from 'lodash';
import { Menu, Dropdown } from 'antd';

import Popover from '../../components/Isomorphic/uielements/popover';
import IntlMessages from '../../components/Isomorphic/utility/intlMessages';

import { clearUserData } from '../../store/modules/User/action';

import TopbarDropdownWrapper from './topbarDropdown.style';

const { SubMenu } = Menu;

class TopbarUser extends Component {
  static propTypes = {
    clearUserData: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false
    };
  }

  hide() {
    this.setState({ visible: false });
  }

  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  handleLogout = () => {
    const { clearUserData } = this.props;

    clearUserData();
  };

  render() {
    const { loginState } = this.props;
    const accessToken = get(loginState, 'result.accessToken', '');
    const decoded = jwtDecode(accessToken);
    const role = get(decoded, 'roles.0', '');

    const avatar = `${process.env.PUBLIC_URL}/images/default-avatar.svg`;

    const menuItems = [
      {
        component: (
          <a className="isoDropdownLink" href="/profile" onClick={this.handleVisibleChange}>
            <IntlMessages id="topbar.profile" />
          </a>
        )
      },
      {
        component: (role === 'admin' || role === 'manage') ? (
          <a className="isoDropdownLink" href="/manager-user">
            <IntlMessages id="topbar.managerUsers" />
          </a>
        ) : null,
      },
      {
        component: role === 'admin' ? (
          <a className="isoDropdownLink" href="/companies">
            <IntlMessages id="themeSwitcher.companies" />
          </a>
        ) : null,
      },
      {
        component: role === 'customer' ? (
          <a className="isoDropdownLink" href="/billing-history">
            Billing Histories
          </a>
        ) : null,
      },
      {
        component: 'Manage Billing',
        children: [
          (role === 'admin' || role === 'manage') ? (
            <a className="isoDropdownLink" href="/billing-charge">
              Charge
            </a>
          ) : null,
          (role === 'admin' || role === 'manage') ? (
            <a className="isoDropdownLink" href="/billing-history">
              Manage billings
            </a>
          ) : null,
        ].filter(Boolean)
      },
      {
        component: (
          <a className="isoDropdownLink" onClick={this.handleLogout} href="# ">
            <IntlMessages id="topbar.logout" />
          </a>
        )
      }
    ].filter(item => Boolean(item.component));

    const menu = (
      <Menu style={{ minWidth: 150 }}>
        {
          menuItems.map((item, index) => {
            const { component, children } = item;
            if (!children) {
              return (
                <Menu.Item key={`menu-${index}`}>
                  {component}
                </Menu.Item>
              );
            }
            return (
              <SubMenu title={component} key={`menu-${index}`}>
                {
                  children.map(child => (
                    <Menu.Item key={component}>
                      {child}
                    </Menu.Item>
                  ))
                }
              </SubMenu>
            );
          })
        }
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        <div className="isoImgWrapper">
          <img alt="user" src={avatar} />
          <span className="userActivity online" />
        </div>
      </Dropdown>
    );
    //
    // const content = (
    //   <TopbarDropdownWrapper className="isoUserDropdown" onClick={this.handleVisibleChange}>
    //     <a className="isoDropdownLink" href="/profile" onClick={this.handleVisibleChange}>
    //       <IntlMessages id="topbar.profile" />
    //     </a>
    //     {(role === 'admin' || role === 'manage')
    //       && <a className="isoDropdownLink" href="/manager-user">
    //         <IntlMessages id="topbar.managerUsers" />
    //       </a>
    //     }
    //
    //     {
    //       role === 'admin'
    //       && <a className="isoDropdownLink" href="/companies">
    //         <IntlMessages id="themeSwitcher.companies" />
    //       </a>
    //     }
    //
    //     <a className="isoDropdownLink" onClick={this.handleLogout} href="# ">
    //       <IntlMessages id="topbar.logout" />
    //     </a>
    //   </TopbarDropdownWrapper>
    // );
    //
    // return (
    //   <Popover
    //     content={content}
    //     trigger="click"
    //     visible={this.state.visible}
    //     onVisibleChange={this.handleVisibleChange}
    //     arrowPointAtCenter={true}
    //     placement="bottomLeft"
    //   >
    //     <div className="isoImgWrapper">
    //       <img alt="user" src={avatar} />
    //       <span className="userActivity online" />
    //     </div>
    //   </Popover>
    // );
  }
}

export default connect(
  state => ({
    loginState: state.user.login
  }),
  dispatch => bindActionCreators({
    clearUserData
  }, dispatch)
)(TopbarUser);
