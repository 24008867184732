import { createActions } from 'redux-actions';

import Api from './api';

/** FETCH EMAIL SUGGESTION **/
const { fetchEmailSuggestionRequest, fetchEmailSuggestionSuccess, fetchEmailSuggestionFail } = createActions({
  FETCH_EMAIL_SUGGESTION_REQUEST: () => { },
  FETCH_EMAIL_SUGGESTION_SUCCESS: data => ({ data }),
  FETCH_EMAIL_SUGGESTION_FAIL: error => ({ error })
});

export const fetchEmailSuggestion = search => (dispatch) => {
  dispatch(fetchEmailSuggestionRequest());
  return Api.fetchEmails(search)
    .then(({ data }) => {
      data = data.map(item => {
        item.display = item.name ? `${item.name} <${item.email}>` : item.email;
        return item;
      });
      dispatch(fetchEmailSuggestionSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchEmailSuggestionFail(error));
    });
};
