import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  message
} from 'antd';
import {
  get,
  chain,
  isEqual,
  debounce
} from 'lodash';

import DesktopMailView from '../Pages/MailBox/desktopView';
import { validateEmail } from '../../utils/common';

import {
  fetchMailByTransaction,
  sendMail
} from '../../store/modules/User/action';

class ModalMailBox extends Component {
  static propTypes = {
    userId: PropTypes.string,
    visible: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleSubmit: PropTypes.func,
    listStaff: PropTypes.array,
    onSubmit: PropTypes.func,
    dispatchSendMail: PropTypes.func,
    transactionIds: PropTypes.array,
    dispatchFetchMailByTransaction: PropTypes.func,
    mailByTransaction: PropTypes.object,
    transactionId: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      initMailData: [],
      mailsData: [],
      replyMail: false,
      selectedMail: '',
      searchString: '',
      isShowModal: false,
      isEnableCompose: false
    };

    this.handleSendMail = debounce(this.onSendMail, 500);
  }

  componentDidMount() {
    // Viet disabled
    // this.fetchAllMails();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const oldAllMail = get(prevState, 'newAllMail', []);
    const newAllMail = get(nextProps.mailByTransaction, 'result.data', []);
    if (!isEqual(oldAllMail, newAllMail)) {
      return {
        initMailData: newAllMail,
        mailsData: newAllMail
      };
    }
    return null;
  }

  fetchAllMails() {
    const { userId, dispatchFetchMailByTransaction, transactionId } = this.props;
    if (userId) {
      dispatchFetchMailByTransaction(userId)
        .then(() => {
          const { mailByTransaction } = this.props;

          const allMail = get(mailByTransaction, 'result.data', []);
          const mailOfTransaction = allMail.filter(ele => ele.id === transactionId);

          this.setState({
            initMailData: transactionId ? mailOfTransaction : allMail,
            mailsData: transactionId ? mailOfTransaction : allMail
          });
        });
    }
  }

  handleFilterBucket = (key, value) => {
    const { initMailData } = this.state;

    const buckets = initMailData.filter(ele => ele.bucket === key.bucket.toUpperCase());
    this.setState({
      composeMail: value,
      mailsData: buckets
    });
  };

  handleSelectMail = id => {
    this.setState({
      selectedMail: id,
      isEnableCompose: true
    });
  };

  // this func is called when user press enter
  handleSearch = text => {
    const { initMailData } = this.state;

    const searchData = initMailData.filter(ele => {
      const propertyName = get(ele, 'properties.0.address', '');

      if (text) {
        return propertyName.toUpperCase()
          .indexOf(text.toUpperCase()) > -1;
      }
      return ele;
    });

    this.setState({ mailsData: searchData });
  };

  // this func is called when user change text
  handleChangeSearch = text => {
    const { initMailData } = this.state;

    const searchData = initMailData.filter(ele => {
      const propertyName = get(ele, 'properties.0.address', '');

      if (text) {
        return propertyName.toUpperCase()
          .indexOf(text.toUpperCase()) > -1;
      }
      return ele;
    });

    this.setState({
      mailsData: searchData,
      searchString: text
    });
  };

  handleChangeReplyMail = value => {
    this.setState({ replyMail: value });
  };

  filterMails = () => {
    const { mailsData } = this.state;

    const allMails = mailsData.filter(ele => ele.id === this.state.selectedMail);
    this.setState({ mailsData: allMails });
  };

  onSendMail = (data, callback) => {
    const { userId, dispatchSendMail } = this.props;
    const { to, cc, subject } = data;

    if (!to) {
      message.error('To field is required');
    } else if (!validateEmail(to)) {
      message.error('To field not match format email');
    } else if (cc && !validateEmail(cc)) {
      message.error('cc field not match format email');
    } else if (!subject) {
      const isConfirm = global.window.confirm('Do you want to send email without subject ?');
      if (isConfirm) {
        dispatchSendMail(userId, data)
          .then(() => {
            const { sendMail } = this.props;
            const { error } = sendMail;

            if (error) {
              message.error('You has sent mail failed, please try again!');
            } else {
              message.success('Email sent successfully!', 3)
                .then(() => {
                  this.fetchAllMails();
                  if (typeof callback === 'function') {
                    callback();
                  }
                });
            }
          });
      }
    } else {
      dispatchSendMail(userId, data)
        .then(() => {
          const { sendMail } = this.props;
          const { error } = sendMail;

          if (error) {
            message.error('You has sent mail failed, please try again!');
          } else {
            message.success('Email sent successfully!', 3)
              .then(() => {
                this.fetchAllMails();
                if (typeof callback === 'function') {
                  callback();
                }
              });
          }
        });
    }
  };

  renderTitleMail = () => {
    const { selectedMail, mailsData } = this.state;

    const propertyAddress = chain(mailsData)
      .find({ id: selectedMail })
      .get('properties.0.address')
      .value();

    return (
      <span style={{ color: '#4482FF' }}>
        MESSAGE CENTER - {selectedMail ? propertyAddress : 'Select a transaction to view or send mail(s)'}
      </span>
    );
  }

  render() {
    const {
      mailsData,
      searchString,
      selectedMail,
      replyMail,
      isEnableCompose
    } = this.state;

    const {
      visible,
      handleCancel,
      onSubmit,
      secondEmail,
      email,
      sendMail,
      withOutContext
    } = this.props;

    const isRequesting = get(sendMail, 'requesting', false);
    const sendMailSuccess = get(sendMail, 'result', '');

    return (
      <Modal
        title={this.renderTitleMail()}
        width="95%"
        visible={visible}
        onOk={onSubmit}
        onCancel={handleCancel}
        bodyStyle={{ padding: 0 }}
      >
        <DesktopMailView
          allMails={mailsData}
          filterAttr={mailsData}
          filterBucket={this.handleFilterBucket}
          selectedMail={selectedMail}
          selectMail={this.handleSelectMail}
          changeReplyMail={this.handleChangeReplyMail}
          replyMail={replyMail}
          filterMails={mailsData}
          searchString={searchString}
          onSearch={this.handleSearch}
          onChangeSearch={this.handleChangeSearch}
          sendMail={this.handleSendMail}
          mailSent={sendMail}
          isEnableCompose={isEnableCompose}
          secondEmail={secondEmail}
          email={email}
          isRequesting={isRequesting}
          sendMailSuccess={sendMailSuccess}
          withOutContext={withOutContext}
        />
      </Modal>
    );
  }
}

export default connect(
  state => ({
    sendMail: state.user.sendMail,
    mailByTransaction: state.user.mailByTransaction,
    notifications: state.user.notifications
  }),
  dispatch => bindActionCreators({
    dispatchFetchMailByTransaction: fetchMailByTransaction,
    dispatchSendMail: sendMail
  }, dispatch)
)(ModalMailBox);
