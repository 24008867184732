import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';

import {
  setAddBillingUserId,
  setVisibleAddAmount,
  addBillingAmount,
  getUserBillingInfo,
} from '../../store/modules/Bliing/action';

import AddBillingForm from './form';
import { getErrorMessage } from '../../utils/common';
import { Modal } from 'antd';

export default function AddBillingCardModal() {
  const dispatch = useDispatch();

  const visibleAddBilling = useSelector(state => state?.billing?.visibleAddAmount);
  const requesting = useSelector(state => state?.billing?.addAmount.requesting);
  const userId = useSelector(state => state?.billing?.addBillingUserId);

  const loading = useSelector(state => state?.billing?.billingInfo?.requesting);

  const handleCancel = () => {
    dispatch(setVisibleAddAmount(false));
  };

  const handleSubmit = useCallback(values => {
    dispatch(addBillingAmount(userId, values))
      .then(res => {
        const { type, payload } = res;
        if (type === 'ADD_BILLING_AMOUNT_FAIL') {
          const errMessage = getErrorMessage(payload.error);
          return Modal.error({ content: errMessage });
        }

        Modal.success({
          content: 'Add successfully.',
          onOk: () => {
            dispatch(setVisibleAddAmount(false));
            dispatch(setAddBillingUserId(''));
          }
        });
      });
  }, [dispatch, userId]);

  const loadUserBilling = useCallback(() => {
    dispatch(getUserBillingInfo(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (userId) {
      loadUserBilling();
    }
  }, [loadUserBilling, userId]);

  return (
    <AddBillingForm
      visible={visibleAddBilling}
      loading={loading}
      requesting={requesting}
      handleCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
}
