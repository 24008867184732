/* eslint-disable */
import React, { Component } from 'react';
import { Button } from 'antd';
import IntlMessages from '../utility/intlMessages';
import MailComposeBtnWrapper from './mailComposeBtn.style';

export default class extends Component {
  render() {
    const { changeComposeMail, isEnableCompose } = this.props;

    return (
      <MailComposeBtnWrapper className="isoComposeBtnWrapper">
        <Button
          className={"buttonEnable"}
          type={"buttonEnable"}
          onClick={event => {
            changeComposeMail(true);
            if (this.props.onDrawerClose) {
              this.props.onDrawerClose();
            }
          }}
          disabled={!isEnableCompose}
        >
          <IntlMessages id="email.compose" />
        </Button>
      </MailComposeBtnWrapper>
    );
  }
}
