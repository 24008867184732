import { handleActions } from 'redux-actions';

const initialState = {
  login: {
    result: null,
    error: null,
    requesting: false
  },
  signUp: {
    result: null,
    error: null,
    requesting: false
  },
  documents: {
    result: [],
    error: null,
    requesting: false
  },
  staff: {
    result: [],
    error: null,
    requesting: false
  },
  sendMail: {
    result: null,
    error: null,
    requesting: false
  },
  mailByTransaction: {
    result: null,
    error: null,
    requesting: false
  },
  updateUserInformation: {
    result: null,
    error: null,
    requesting: false
  },
  updateUserPassword: {
    result: null,
    error: null,
    requesting: false
  },
  userInfo: {
    result: null,
    error: null,
    requesting: false
  },
  listUser: {
    result: null,
    error: null,
    requesting: false
  },
  setRoleForUser: {
    result: null,
    error: null,
    requesting: false
  },
  changeUserPassword: {
    result: null,
    error: null,
    requesting: false
  },
  notifications: {
    result: null,
    error: null,
    requesting: false
  },
  readNotifications: {
    result: null,
    error: null,
    requesting: false
  },
  addCompany: {
    requesting: false,
    result: null,
    error: null
  },
  users: {
    requesting: false,
    result: [],
    error: null
  },
  bannedUser: {
    result: null,
    error: null,
    requesting: false
  },
  unbannedUser: {
    result: null,
    error: null,
    requesting: false
  },
};

export const user = handleActions({
  /** LOGIN **/
  LOGIN_REQUEST: (state) => ({
    ...state,
    login: {
      ...state.login,
      requesting: true,
      error: null
    }
  }),
  LOGIN_SUCCESS: (state, { payload }) => ({
    ...state,
    login: {
      ...state.login,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  LOGIN_FAIL: (state, { payload }) => ({
    ...state,
    login: {
      ...state.login,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** SIGN UP **/
  SIGN_UP_REQUEST: (state) => ({
    ...state,
    signUp: {
      ...state.signUp,
      requesting: true,
      error: null
    }
  }),
  SIGN_UP_SUCCESS: (state, { payload }) => ({
    ...state,
    signUp: {
      ...state.signUp,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  SIGN_UP_FAIL: (state, { payload }) => ({
    ...state,
    signUp: {
      ...state.signUp,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),


  /** FETCH USERS DOCUMENTS **/
  FETCH_USER_DOCUMENTS_REQUEST: (state) => ({
    ...state,
    documents: {
      ...state.documents,
      requesting: true,
      error: null
    }
  }),
  FETCH_USER_DOCUMENTS_SUCCESS: (state, { payload }) => ({
    ...state,
    documents: {
      ...state.documents,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_USER_DOCUMENTS_FAIL: (state, { payload }) => ({
    ...state,
    documents: {
      ...state.documents,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  SEND_MAIL_REQUEST: state => ({
    ...state,
    sendMail: {
      ...state.sendMail,
      requesting: true,
      error: null
    }
  }),
  SEND_MAIL_SUCCESS: (state, { payload }) => ({
    ...state,
    sendMail: {
      ...state.sendMail,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  SEND_MAIL_FAIL: (state, { payload }) => ({
    ...state,
    sendMail: {
      ...state.sendMail,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  FETCH_MAIL_BY_TRANSACTION_REQUEST: state => ({
    ...state,
    mailByTransaction: {
      ...state.mailByTransaction,
      requesting: true,
      error: null
    }
  }),
  FETCH_MAIL_BY_TRANSACTION_SUCCESS: (state, { payload }) => ({
    ...state,
    mailByTransaction: {
      ...state.mailByTransaction,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_MAIL_BY_TRANSACTION_FAIL: (state, { payload }) => ({
    ...state,
    mailByTransaction: {
      ...state.mailByTransaction,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** UPDATE USERS INFORMATION **/
  UPDATE_USER_INFORMATION_REQUEST: (state) => ({
    ...state,
    updateUserInformation: {
      ...state.updateUserInformation,
      requesting: true,
      error: null
    }
  }),
  UPDATE_USER_INFORMATION_SUCCESS: (state, { payload }) => ({
    ...state,
    updateUserInformation: {
      ...state.updateUserInformation,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  UPDATE_USER_INFORMATION_FAIL: (state, { payload }) => ({
    ...state,
    updateUserInformation: {
      ...state.updateUserInformation,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** UPDATE USERS PASSWORD **/
  UPDATE_USER_PASSWORD_REQUEST: (state) => ({
    ...state,
    updateUserPassword: {
      ...state.updateUserPassword,
      requesting: true,
      error: null
    }
  }),
  UPDATE_USER_PASSWORD_SUCCESS: (state, { payload }) => ({
    ...state,
    updateUserPassword: {
      ...state.updateUserPassword,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  UPDATE_USER_PASSWORD_FAIL: (state, { payload }) => ({
    ...state,
    updateUserPassword: {
      ...state.updateUserPassword,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** FETCH USERS INFORMATION **/
  FETCH_USER_INFORMATION_REQUEST: (state) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      requesting: true,
      error: null
    }
  }),
  FETCH_USER_INFORMATION_SUCCESS: (state, { payload }) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_USER_INFORMATION_FAIL: (state, { payload }) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** FETCH USERS INFORMATION **/
  FETCH_ALL_USER_REQUEST: (state) => ({
    ...state,
    listUser: {
      ...state.listUser,
      requesting: true,
      error: null
    }
  }),
  FETCH_ALL_USER_SUCCESS: (state, { payload }) => ({
    ...state,
    listUser: {
      ...state.listUser,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_ALL_USER_FAIL: (state, { payload }) => ({
    ...state,
    listUser: {
      ...state.listUser,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** SET ROLE FOR USER **/
  SET_ROLE_FOR_USER_REQUEST: (state) => ({
    ...state,
    setRoleForUser: {
      ...state.setRoleForUser,
      requesting: true,
      error: null
    }
  }),
  SET_ROLE_FOR_USER_SUCCESS: (state, { payload }) => ({
    ...state,
    setRoleForUser: {
      ...state.setRoleForUser,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  SET_ROLE_FOR_USER_FAIL: (state, { payload }) => ({
    ...state,
    setRoleForUser: {
      ...state.setRoleForUser,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** ADMIN CHANGE USER PASSWORD **/
  ADMIN_CHANGE_USER_PASSWORD_REQUEST: (state) => ({
    ...state,
    changeUserPassword: {
      ...state.changeUserPassword,
      requesting: true,
      error: null
    }
  }),
  ADMIN_CHANGE_USER_PASSWORD_SUCCESS: (state, { payload }) => ({
    ...state,
    changeUserPassword: {
      ...state.changeUserPassword,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  ADMIN_CHANGE_USER_PASSWORD_FAIL: (state, { payload }) => ({
    ...state,
    changeUserPassword: {
      ...state.changeUserPassword,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** FETCH UNREAD NOTIFICATIONS REQUEST **/
  FETCH_UNREAD_NOTIFICATIONS_REQUEST: (state) => ({
    ...state,
    notifications: {
      ...state.notifications,
      requesting: true,
      error: null
    }
  }),
  FETCH_UNREAD_NOTIFICATIONS_SUCCESS: (state, { payload }) => ({
    ...state,
    notifications: {
      ...state.notifications,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_UNREAD_NOTIFICATIONS_FAIL: (state, { payload }) => ({
    ...state,
    notifications: {
      ...state.notifications,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** FETCH UNREAD NOTIFICATIONS REQUEST **/
  READ_NOTIFICATIONS_REQUEST: (state) => ({
    ...state,
    readNotifications: {
      ...state.readNotifications,
      requesting: true,
      error: null
    }
  }),
  READ_NOTIFICATIONS_SUCCESS: (state, { payload }) => ({
    ...state,
    readNotifications: {
      ...state.readNotifications,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  READ_NOTIFICATIONS_FAIL: (state, { payload }) => ({
    ...state,
    readNotifications: {
      ...state.readNotifications,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** ADD COMPANY **/
  ADD_COMPANY_REQUEST: (state) => ({
    ...state,
    addCompany: {
      ...state.addCompany,
      requesting: true,
      error: null
    }
  }),
  ADD_COMPANY_SUCCESS: (state, { payload }) => ({
    ...state,
    addCompany: {
      ...state.addCompany,
      requesting: false,
      result: payload.data
    }
  }),
  ADD_COMPANY_FAIL: (state, { payload }) => ({
    ...state,
    addCompany: {
      ...state.addCompany,
      requesting: false,
      error: payload.error
    }
  }),
  FETCH_USERS_REQUEST: (state) => ({
    ...state,
    users: {
      ...state.users,
      requesting: true,
      error: null
    }
  }),
  FETCH_USERS_SUCCESS: (state, { payload }) => ({
    ...state,
    users: {
      ...state.users,
      requesting: false,
      result: payload.data
    }
  }),
  FETCH_USERS_FAIL: (state, { payload }) => ({
    ...state,
    users: {
      ...state.users,
      requesting: false,
      error: payload.error
    }
  }),

  /** INITIAL USER DATA **/
  SET_INITIAL_USER_DATA: () => initialState,

  /** BANNED USER **/
  BANNED_USER_REQUEST: (state) => ({
    ...state,
    bannedUser: {
      ...state.bannedUser,
      requesting: true
    }
  }),
  BANNED_USER_SUCCESS: (state, { payload }) => ({
    ...state,
    bannedUser: {
      ...state.bannedUser,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  BANNED_USER_FAIL: (state, { payload }) => ({
    ...state,
    bannedUser: {
      ...state.bannedUser,
      requesting: false,
      error: payload.error
    }
  }),

  /** UNBANNED USER **/
  UNBANNED_USER_REQUEST: (state) => ({
    ...state,
    unbannedUser: {
      ...state.unbannedUser,
      requesting: true
    }
  }),
  UNBANNED_USER_SUCCESS: (state, { payload }) => ({
    ...state,
    unbannedUser: {
      ...state.unbannedUser,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  UNBANNED_USER_FAIL: (state, { payload }) => ({
    ...state,
    unbannedUser: {
      ...state.unbannedUser,
      requesting: false,
      error: payload.error
    }
  }),
}, initialState);

export default user;
