import {
  createAction,
  createActions
} from 'redux-actions';

import Api from './api';

const { fetchBillingHistoriesRequest, fetchBillingHistoriesSuccess, fetchBillingHistoriesFail } = createActions({
  FETCH_BILLING_HISTORIES_REQUEST: () => { },
  FETCH_BILLING_HISTORIES_SUCCESS: data => ({ data }),
  FETCH_BILLING_HISTORIES_FAIL: error => ({ error })
});

export const fetchBillingHistories = (params) => (dispatch) => {
  dispatch(fetchBillingHistoriesRequest());
  return Api.fetchBillingHistories(params)
    .then(({ data }) => {
      dispatch(fetchBillingHistoriesSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchBillingHistoriesFail(error));
    });
};
const { fetchListChargesRequest, fetchListChargesSuccess, fetchListChargesFail } = createActions({
  FETCH_LIST_CHARGES_REQUEST: () => { },
  FETCH_LIST_CHARGES_SUCCESS: data => ({ data }),
  FETCH_LIST_CHARGES_FAIL: error => ({ error })
});

export const fetchListCharges = (params) => (dispatch) => {
  dispatch(fetchListChargesRequest());
  return Api.fetchListCharges(params)
    .then(({ data }) => {
      dispatch(fetchListChargesSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchListChargesFail(error));
    });
};

const { setCardInvalidRequest, setCardInvalidSuccess, setCardInvalidFail } = createActions({
  SET_CARD_INVALID_REQUEST: () => { },
  SET_CARD_INVALID_SUCCESS: data => ({ data }),
  SET_CARD_INVALID_FAIL: error => ({ error })
});

export const setCardInvalid = (userId, body) => (dispatch) => {
  dispatch(setCardInvalidRequest());

  return Api.setCardInvalid(userId, body)
    .then(({ data }) => {
      return dispatch(setCardInvalidSuccess(data));
    })
    .catch((error) => {
      return dispatch(setCardInvalidFail(error));
    });
};

const { approveChargeRequest, approveChargeSuccess, approveChargeFail } = createActions({
  APPROVE_CHARGE_REQUEST: () => { },
  APPROVE_CHARGE_SUCCESS: data => ({ data }),
  APPROVE_CHARGE_FAIL: error => ({ error })
});

export const setApproveCharge = (userId) => (dispatch) => {
  dispatch(approveChargeRequest());

  return Api.setApproveCharge(userId)
    .then(({ data }) => {
      return dispatch(approveChargeSuccess(data));
    })
    .catch((error) => {
      return dispatch(approveChargeFail(error));
    });
};

const { getUserBillingInfoRequest, getUserBillingInfoSuccess, getUserBillingInfoFail } = createActions({
  GET_USER_BILLING_INFO_REQUEST: () => { },
  GET_USER_BILLING_INFO_SUCCESS: data => ({ data }),
  GET_USER_BILLING_INFO_FAIL: error => ({ error })
});

export const getUserBillingInfo = (userId) => (dispatch) => {
  dispatch(getUserBillingInfoRequest());

  return Api.getUserBillingInfo(userId)
    .then(({ data }) => {
      dispatch(getUserBillingInfoSuccess(data));
    })
    .catch((error) => {
      dispatch(getUserBillingInfoFail(error));
    });
};

const { updateBillingRequest, updateBillingSuccess, updateBillingFail } = createActions({
  UPDATE_BILLING_REQUEST: () => { },
  UPDATE_BILLING_SUCCESS: data => ({ data }),
  UPDATE_BILLING_FAIL: error => ({ error })
});

export const updateBilling = (body) => (dispatch) => {
  dispatch(updateBillingRequest());

  return Api.updateBilling(body)
    .then(({ data }) => {
      return dispatch(updateBillingSuccess(data));
    })
    .catch((error) => {
      return dispatch(updateBillingFail(error));
    });
};

const { addBillingAmountRequest, addBillingAmountSuccess, addBillingAmountFail } = createActions({
  ADD_BILLING_AMOUNT_REQUEST: () => { },
  ADD_BILLING_AMOUNT_SUCCESS: data => ({ data }),
  ADD_BILLING_AMOUNT_FAIL: error => ({ error })
});

export const addBillingAmount = (userId, body) => (dispatch) => {
  dispatch(addBillingAmountRequest());

  return Api.addBillingAmount(userId, body)
    .then(({ data }) => {
      return dispatch(addBillingAmountSuccess(data));
    })
    .catch((error) => {
      return dispatch(addBillingAmountFail(error));
    });
};

export const setVisibleAddAmount = createAction('SET_VISIBLE_ADD_AMOUNT');
export const setAddBillingUserId = createAction('SET_ADD_BILLING_USER_ID');
