import CryptoJS from 'crypto-js';
import aes from 'browserify-aes';
import crypto from 'crypto';
import { isString } from 'lodash';

export const encryptAES = (data, key, iv) => {
  if (!isString(data)) {
    return null;
  }

  const cipher = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });

  return cipher.toString();
};

export const decryptAES = (ciphertext, key, iv) => {
  if (!isString(ciphertext)) {
    return null;
  }

  const encrypted = ciphertext.toString(CryptoJS.enc.Base64);
  const decrypted = CryptoJS.AES.decrypt(encrypted, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const generateAESKeyRandom = () => {
  const wordArrayRandom = CryptoJS.lib.WordArray.random(16);
  const AES256keyRandom = CryptoJS.enc.Hex.stringify(wordArrayRandom)
    .toLocaleUpperCase();

  return AES256keyRandom;
};

export const encodeBase64 = (data) => {
  const wordArray = CryptoJS.enc.Utf8.parse(data);
  const base64 = CryptoJS.enc.Base64.stringify(wordArray);

  return base64;
};

export const decryptString = (encrypted, key) => {
  const keyHash = crypto.createHash('md5').update(key).digest('hex');
  const textParts = encrypted.split(':');
  const ivPart = textParts.shift();
  if (ivPart) {
    const iv = Buffer.from(ivPart, 'hex');
    const encryptedText = Buffer.from(textParts.join(':'), 'hex');
    const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(keyHash.substr(0, 32).padStart(32, '*')), iv);
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
  }
  return null;
};
