/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  get,
  sumBy,
  debounce
} from 'lodash';
import {
  Icon,
  Upload,
  message
} from 'antd';
import moment from 'moment';

import Button from '../uielements/button';
import Input from '../uielements/input';
import ComposeAutoCompleteWithContext from './composeAutoComplete';
import ComposeAutoCompleteWithOutContext from '../../ComposeAutoComplete';
import IntlMessages from '../utility/intlMessages';
import ComposeForm from './composeMail.style';
import Editor from '../../../components/Isomorphic/uielements/editor';
import ModalUploadDocument from '../../../containers/Pages/MailBox/modalUploadDocument';

import { validateEmail } from '../../../utils/common';
import { fetchEmailSuggestion } from '../../../store/modules/EmailStore/action';
import { getDocumentItems } from '../../../store/modules/Document/action';
import { LogoBase64, LineBase64 } from '../../../constants/defaultValues';

class ComposeMail extends Component {
  constructor(props) {
    super(props);
    let editorState = get(props, 'body', '');
    if (editorState.indexOf('html') !== -1) {
      editorState = /<body.*?>([\s\S]*)<\/body>/.exec(body)[1];
    }
    if (editorState && props.mailData) {
      editorState =  `
                <br/>
                <br/>
                <br/>
                <blockquote>-----------------------------------------</blockquote> 
                <i><strong>To:</strong> ${props.mailData.to}</i><br/>
                <i><strong>From:</strong> ${props.mailData.from}</i><br/>
                <i><strong>At:</strong> ${moment(props.mailData.createdAt).format('MM-DD-YYYY HH:mm:ss')}</i><br/>
                <strong><i>${props.subject}</i></strong><br/>
                <br/>
                <br/>
                 ${editorState} </br>
              `;
    }

    const shareLink = get(props, 'shareLink', '');
    const subject = get(props, 'subject', '');
    if (shareLink) {
      editorState = `
        <img src="${LogoBase64}" alt="logo" width="300" />
        <br/>
        <img src="${LineBase64}" alt="logo" width="300" />
      
        <br/>
        <p>${get(props, 'user.result.firstName')} is sending you the attached title search for ${subject}. </p>
        <br/>
        <p>In addition, we offer FREE IntelliSearch (TM) platform for FAST title review and examination, selective download, transcript, and summary of search documents, and much more.  Please click on the link below:</p>
        <br/>
        <a href="${shareLink}">${shareLink}</a>
         <br/>
        <br/>
        <p>Title Logics Support Team</p>
      `;
    }

    this.state = {
      editorState,
      loading: false,
      iconLoading: false,
      valueTo: '',
      valueCc: '',
      valueSubject: '',
      mailSuggestion: [],
      fileUpload: [],
      visible: false
    };

    this.handleOnSuggestion = debounce(this.onSuggestion, 300);
  }

  propsComposeAutoCompleteTO = () => {
    const {
      email,
      allMail,
      lastEmail,
      mailBoxes,
      secondEmail,
      replyMailTO
    } = this.props;

    const ComposeAutoCompleteTO = {
      email,
      allMail,
      lastEmail,
      mailBoxes,
      secondEmail,
      tags: replyMailTO,
      autofocus: true,
      placeholder: 'To',
      onChange: this.handleOnChange('valueTo'),
      handleTab: this.onTab('valueTo')
    };
    return ComposeAutoCompleteTO;
  };

  propsComposeAutoCompleteCC = () => {
    const {
      email,
      allMail,
      mailBoxes,
      secondEmail,
      replyMailCC
    } = this.props;

    const ComposeAutoCompleteCC = {
      email,
      allMail,
      mailBoxes,
      secondEmail,
      tags: replyMailCC,
      placeholder: 'CC',
      onChange: this.handleOnChange('valueCc'),
      handleTab: this.onTab('valueCc')
    };
    return ComposeAutoCompleteCC;
  };

  propsEditorOption = () => {
    const { editorState } = this.state;

    const editorOption = {
      style: { width: '90%', height: '70%' },
      editorState,
      toolbarClassName: 'home-toolbar',
      wrapperClassName: 'home-wrapper',
      editorClassName: 'home-editor',
      onEditorStateChange: this.handleOnChange('editorState')
    };
    return editorOption;
  };

  handleOnChange = key => (value) => {
    if (key === 'valueSubject') {
      this.setState({ [key]: [value.target.value].toString() });
    } else if (key === 'valueTo' || key === 'valueCc') {
      // to avoid the javascript string escape
      const valueRegex = value.replace(/\\/g, '\\\\');
      this.onSuggestion(valueRegex);
      if (validateEmail([value].toString())) {
        this.setState({
          [key]: [value].toString()
            .trim()
        });
      }
    } else {
      this.setState({
        [key]: [value].toString()
      });
    }
  };

  onTab = (key) => (tag) => {
    const mailTO = tag.map(ele => ele.text);
    this.setState({ [key]: [...mailTO].toString() });
  };

  onSuggestion = (value) => {
    const { dispatchFetchEmailSuggestion } = this.props;

    dispatchFetchEmailSuggestion(value)
      .then(() => {
        const { emails } = this.props;
        const arrSuggest = get(emails, 'result', []);
        this.setState({ mailSuggestion: arrSuggest });
      });
  };

  handleAttachment = (info) => {
    if (info.file.status === 'done') {
      const data = info.fileList;
      let attachment = data && data.map(ele => (ele.error ? null : {
        name: ele.name,
        type: ele.type,
        content: ele.response,
        size: ele.size
      }));
      attachment = attachment.filter(item => !!item);
      this.setState({ fileUpload: attachment });
    } else if (info.file.status === 'error') {
      const status = get(info, 'file.error', `${info.file.name} file upload failed.`);
      message.error(status);
    }
  };

  renderAttachmentBtn = () => {
    const props = {
      name: 'file',
      customRequest: ({ onSuccess, onError, file }) => {
        const { fileUpload } = this.state;
        const total = sumBy(fileUpload, 'size') || 0;
        return new Promise(((resolve, reject) => {
          if (((total + file.size) / 1000 / 1000) > 20) {
            return setTimeout(() => reject(onError('Limit 20MB')), 0);
          }
          this.getBase64(file)
            .then(result => {
              result = result.split('base64,');
              resolve(onSuccess(result[1]));
            })
            .catch(error => reject(onError(error)));
        }));
      },
      onChange: (info) => this.handleAttachment(info),
      ref: (ref) => { this.refAttachments = ref; }
    };

    return (
      <div style={{ marginBottom: 10 }}>
        <Upload {...props}>
          <Button type="dashed">
            <Icon type="paper-clip" /> Attach Files
          </Button>
        </Upload>
      </div>
    );
  };

  renderDocumentBtn = () => {
    const { documentItems, dispatchGetDocumentItems } = this.props;
    const data = documentItems;

    return (
      <div style={{ marginBottom: 10 }}>
        <Button style={{ marginBottom: 10 }} onClick={() => { this.setState({ visible: true }); }} type="dashed">
          <Icon type="file-pdf" /> Attach Documents
        </Button>
        {
          data && data.map((ele, index) => {
            return (
              <div key={index} className="list-document">
                <div>
                  <Icon style={{ marginTop: 2 }} type="paper-clip" />
                  <span style={{ marginLeft: 10 }}>{ele.fileName}</span>
                </div>

                <div
                  className="close-btn"
                  onClick={() => dispatchGetDocumentItems(data.filter(item => item !== ele))}
                >
                  <Icon style={{ fontSize: 10 }} type="close" />
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleSubmit = (data) => () => {
    const { dispatchGetDocumentItems } = this.props;
    dispatchGetDocumentItems(data);
    this.setState({ visible: false });
  };

  handleClearContent = () => {
    const { dispatchGetDocumentItems } = this.props;
    dispatchGetDocumentItems([]);
    this.setState({
      editorState: '',
      fileUpload: []
    }, () => {
      if (this.refAttachments) {
        this.refAttachments.setState({ fileList: [] });
      }
    });
  }

  render() {
    const {
      valueTo,
      valueCc,
      valueSubject,
      editorState,
      mailSuggestion,
      fileUpload,
      visible
    } = this.state;

    const {
      replyId,
      subject,
      sendMail,
      isRequesting,
      selectedMail,
      changeComposeMail,
      handleSuccessSendMail,
      documentItems,
      withOutContext,
      shareLink
    } = this.props;

    const documents = documentItems && documentItems.map(ele => ({
      name: ele.name,
      fileName: ele.fileName,
      link: ele.link,
      type: ele.type
    }));

    const valueForm = {
      to: valueTo,
      cc: valueCc,
      subject: valueSubject || subject,
      body: editorState,
      transactionId: selectedMail,
      replyId: replyId,
      attachments: fileUpload,
      documents
    };

    const sendBtn = (
      <Button
        type="primary"
        style={{ marginRight: 10 }}
        onClick={() => {
          sendMail(valueForm, () => {
            this.handleClearContent();
            handleSuccessSendMail(false);
          });
        }}
        className="isoSendMailBtn"
        loading={isRequesting}
      >
        <IntlMessages id="email.send" />
      </Button>
    );

    const cancelBtn = (
      <Button
        type="danger"
        onClick={() => changeComposeMail(false)}
        className="isoCancelMailBtn"
      >
        <IntlMessages id="email.cancel" />
      </Button>
    );

    return (
      <ComposeForm className="isoComposeMailWrapper" style={{ paddingTop: 0 }}>
        <div className="isoComposeMailBtnWrapper" style={{ marginTop: 0, marginBottom: 10, justifyContent: 'flex-end' }}>
          {this.props.mobileView || this.props.tabView ? (
            <Button
              type="primary"
              onClick={() => changeComposeMail(false)}
              className="isoCancelMailBtn"
            >
              <IntlMessages id="email.cancel" />
            </Button>
          ) : (
            ''
          )}
        </div>
        {
          withOutContext
            ? (
              <div>
                <ComposeAutoCompleteWithOutContext sendBtn={sendBtn} cancelBtn={cancelBtn} mailSuggestion={mailSuggestion} {...this.propsComposeAutoCompleteTO()} />
                <ComposeAutoCompleteWithOutContext mailSuggestion={mailSuggestion} {...this.propsComposeAutoCompleteCC()} />
              </div>
            ) : (
              <div>
                <ComposeAutoCompleteWithContext sendBtn={sendBtn} cancelBtn={cancelBtn} mailSuggestion={mailSuggestion} {...this.propsComposeAutoCompleteTO()} />
                <ComposeAutoCompleteWithContext mailSuggestion={mailSuggestion} {...this.propsComposeAutoCompleteCC()} />
              </div>
            )
        }
        {
          <Input
            className="isoInputBox"
            placeholder="Subject"
            value={valueSubject || subject}
            onChange={this.handleOnChange('valueSubject')}
            disabled={!!subject && this.state.shareLink}
          />
        }

        {this.renderAttachmentBtn()}
        {this.renderDocumentBtn()}

        <Editor {...this.propsEditorOption()} />
        <ModalUploadDocument
          visible={visible}
          handleCancel={this.handleCancel}
          handleSubmit={this.handleSubmit}
          transactionId={selectedMail}
        />
      </ComposeForm>
    );
  }
}

export default connect(
  state => ({
    user: state.user.login,
    emails: state.emailStore.emails,
    documentItems: state.document.documentItems
  }),
  dispatch => bindActionCreators({
    dispatchFetchEmailSuggestion: fetchEmailSuggestion,
    dispatchGetDocumentItems: getDocumentItems
  }, dispatch)
)(ComposeMail);
