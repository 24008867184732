import React, { Component } from 'react';
import {
  Icon,
  Modal,
  Button
} from 'antd';

import FileSaver from 'file-saver';
import axios from 'axios';

const styles = {
  attachmentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  attachmentLeft: {
    width: '78%',
    display: 'flex',
    flexDirection: 'row'
  },
  attachmentRight: {
    width: '20%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    cursor: 'pointer'
  },
  iconDownload: {
    marginRight: 5,
    marginTop: 2,
    color: '#40a9ff'
  }
};

class DownloadFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderFooter = () => {
    return (
      <Button type="primary" onClick={this.handleDownloadAll}>
        Download All
      </Button>
    );
  }

  renderAttachments = () => {
    const { data } = this.props;

    const items = data.map((ele, index) => {
      return (
        <div key={index} style={styles.attachmentContainer}>
          <div style={styles.attachmentLeft}>
            <Icon style={{ marginTop: 2 }} type="file-pdf" />
            <p style={{ marginLeft: 10 }}>{ele.name}</p>
          </div>

          <div style={styles.attachmentRight}>
            <Icon type="cloud-download" style={styles.iconDownload}/>
            <a href={ele.link} download style={{ color: '#40a9ff' }}>Download</a>
          </div>
        </div>
      );
    });

    return items;
  }

  handleDownloadAll = () => {
    const { data } = this.props;

    if (!data.length) {
      return;
    }

    data.forEach(ele => {
      const link = ele.link;
      const type = ele.type;
      setTimeout(() => {
        axios({
          url: link,
          method: 'GET',
          responseType: 'blob'
        })
          .then((response) => {
            /* eslint-disable no-useless-escape */
            const fileName = link.split('/')
              .pop()
              .replace(/[\#\?].*$/, '');
            const blob = new Blob([response.data], { type });
            FileSaver.saveAs(blob, fileName);
          });
      }, 100);
    });
  };

  render() {
    const {
      visible,
      handleCancel
    } = this.props;

    return (
      <Modal
        title="Attachments"
        width="50%"
        visible={visible}
        onCancel={handleCancel}
        footer={this.renderFooter()}
      >
        {this.renderAttachments()}
      </Modal>
    );
  }
}

export default DownloadFiles;
