const siteConfig = {
  siteName: 'TitleLogics',
  siteIcon: 'ion-flash',
  footerText: '© 2019 TitleLogics LLC'
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};
const language = 'english';

export { siteConfig, language, themeConfig };
