import React, { Component } from 'react';
import {
  get,
  head
} from 'lodash';
import { Icon } from 'antd';

import ComposeMail from './composeMail';
import ToggleEmailBody from '../../../components/ToggleEmailBody';
import ModalDownloadFiles from '../../../containers/Pages/MailBox/modalDownloadFiles';
import { timeDifference } from '../../../helpers/utility';

import {
  SingleMailBody,
  SingleMailContents,
  SingleMailInfo,
  SingleMailReply
} from './singleMail.style';
import { validateEmail } from '../../../utils/common';

class SingleMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      composeMail: false,
      isToggleBodyMail: false,
      visibleDownload: false,
      data: []
    };
  }

  renderMailInfo = (ele) => {
    const { secondEmail, email } = this.props;

    const from = get(ele, 'from', '');
    const to = get(ele, 'to', '');
    const attachments = get(ele, 'attachments', []);
    const documents = get(ele, 'documents', []);

    const formatAttachments = attachments.map(ele => ({
      name: ele.name,
      link: ele.link,
      type: ele.type
    }));
    const formatDocuments = documents.map(ele => ({
      name: ele.fileName,
      link: ele.link,
      type: ele.type
    }));

    const filesDownload = [...formatAttachments, ...formatDocuments];

    return (
      <div className="isoMailAddress">
        <div className="isoAddress">
          {
            secondEmail === from
              ? <h3><span>Me {`<${from}>`}</span></h3>
              : <h3><span>{from}</span></h3>
          }
          <span className="mailDate">{timeDifference(ele.createdAt)}</span>
        </div>
        <div className="isoAddress">
          {
            email === to
              ? <p>To: me</p>
              : <p>To: {to.replace(/,/g, ', ')}</p>
          }
          {
            filesDownload && filesDownload.length ? (
              <span className="attachment-file pointer" onClick={this.handleShowModal(filesDownload)}>
                <Icon type="paper-clip" />
                Attachments
              </span>
            ) : null
          }
        </div>
      </div>
    );
  };

  handleShowModal = (attachments) => () => {
    this.setState({
      visibleDownload: true,
      data: attachments
    });
  };

  handleCloseModal = () => {
    this.setState({ visibleDownload: false });
  };

  handleChangeComposeMail = value => () => {
    this.setState({ composeMail: value });
  };

  handleSuccessSendMail = value => {
    this.setState({ composeMail: value });
  };

  render() {
    const {
      composeMail,
      visibleDownload,
      data
    } = this.state;

    const {
      index,
      email,
      sendMail,
      allMails,
      secondEmail,
      selectedMail,
      isRequesting,
      withOutContext
    } = this.props;

    const mail = allMails[index];
    const messengers = get(mail, 'messengers', []);
    const to = get(head(messengers), 'to', '');
    const from = get(head(messengers), 'from', '');

    let lastEmail = '';
    if (validateEmail(to)) {
      const emailData = to.match(/(?:"?([^"]*)"?\s)?(?:<?(.+@[^>]+)>?)/i);
      if (emailData && emailData.length) {
        lastEmail = emailData[2];
      }
    }

    lastEmail = ![email, secondEmail].includes(lastEmail) ? to : from;

    const renderSingleMail = () => (
      messengers.map((ele, index) => {
        return (
          <div key={index}>
            <SingleMailInfo className="isoMailInfo">
              {this.renderMailInfo(ele)}
            </SingleMailInfo>

            <SingleMailBody className="isoMailBody">
              <ToggleEmailBody
                replyMail={this.handleChangeComposeMail(true)}
                mailData={ele}
                index={index}
                sendMail={sendMail}
                isRequesting={isRequesting}
                selectedMail={selectedMail}
                email={email}
                secondEmail={secondEmail}
                withOutContext={withOutContext}
              />
            </SingleMailBody>
          </div>
        );
      })
    );

    return (
      <SingleMailContents className="isoSingleMailContents">
        <div className="isoSingleMail">
          {renderSingleMail()}

          <SingleMailReply className="isoReplyMail">
            {
              composeMail
                ? (
                  <ComposeMail
                    allMail={allMails}
                    sendMail={sendMail}
                    selectedMail={selectedMail}
                    changeComposeMail={this.handleChangeComposeMail}
                    handleSuccessSendMail={this.handleSuccessSendMail}
                    lastEmail={lastEmail}
                    mailBoxes={messengers}
                    isRequesting={isRequesting}
                    withOutContext={withOutContext}
                  />
                ) : (
                  <div
                    onClick={this.handleChangeComposeMail(true)}
                    className="isoReplyMailBtn"
                  >
                    Click here to <span className="pointer">Reply</span>
                  </div>
                )
            }
            <ModalDownloadFiles
              data={data}
              visible={visibleDownload}
              handleCancel={this.handleCloseModal}
            />
          </SingleMailReply>
        </div>
      </SingleMailContents>
    );
  }
}

export default SingleMail;

