import { connectRouter } from 'connected-react-router';
import { get } from 'lodash';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import { clearUserData } from './modules/User/action';
import rootReducer from './rootReducer';

const createHistory = require('history');

const authenticationMiddleware = store => next => (action) => {
  if (
    get(action, 'payload.error.data.error.code') === 'AUTHORIZATION_REQUIRED'
    || get(action, 'payload.error.data.error.name') === 'TokenExpiredError'
    || get(action, 'payload.error.data.error.name') === 'JWT Expired'
  ) {
    return store.dispatch(clearUserData());
  }

  return next(action);
};

function configureStoreProd(initialState = {}) {
  const history = createHistory.createBrowserHistory();
  const middleware = [thunk, authenticationMiddleware];

  const store = createStore(
    combineReducers({
      ...rootReducer,
      router: connectRouter(history),
    }),
    initialState,
    compose(
      applyMiddleware(...middleware),
    ),
  );

  const persistor = persistStore(store);

  return { store, persistor, history };
}

function configureStoreDev(initialState = {}) {
  const history = createHistory.createBrowserHistory();
  const middleware = [thunk, authenticationMiddleware];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    combineReducers({
      ...rootReducer,
      router: connectRouter(history),
    }),
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware),
    ),
  );

  const persistor = persistStore(store);

  return { store, persistor, history };
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
