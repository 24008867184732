import { handleActions } from 'redux-actions';

const initialState = {
  all: {
    requesting: false,
    result: [],
    error: null
  },
  charges: {
    requesting: false,
    result: [],
    error: null
  },
  cardInvalid: {
    result: null,
    error: null,
    requesting: false
  },
  approveCharge: {
    result: null,
    error: null,
    requesting: false
  },
  billingInfo: {
    result: null,
    error: null,
    requesting: false
  },
  updateBilling: {
    result: null,
    error: null,
    requesting: false
  },
  addAmount: {
    result: null,
    error: null,
    requesting: false
  },
  visibleAddAmount: false,
  addBillingUserId: '',
};

export const billing = handleActions({
  FETCH_BILLING_HISTORIES_REQUEST: (state) => ({
    ...state,
    all: {
      ...state.all,
      requesting: true,
      error: null
    }
  }),
  FETCH_BILLING_HISTORIES_SUCCESS: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      requesting: false,
      result: payload.data
    }
  }),
  FETCH_BILLING_HISTORIES_FAIL: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      requesting: false,
      error: payload.error
    }
  }),
  FETCH_LIST_CHARGES_REQUEST: (state) => ({
    ...state,
    charges: {
      ...state.charges,
      requesting: true,
      error: null
    }
  }),
  FETCH_LIST_CHARGES_SUCCESS: (state, { payload }) => ({
    ...state,
    charges: {
      ...state.charges,
      requesting: false,
      result: payload.data
    }
  }),
  FETCH_LIST_CHARGES_FAIL: (state, { payload }) => ({
    ...state,
    charges: {
      ...state.charges,
      requesting: false,
      error: payload.error
    }
  }),

  GET_USER_BILLING_INFO_REQUEST: (state) => ({
    ...state,
    billingInfo: {
      ...state.billingInfo,
      requesting: true
    }
  }),
  GET_USER_BILLING_INFO_SUCCESS: (state, { payload }) => ({
    ...state,
    billingInfo: {
      ...state.billingInfo,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  GET_USER_BILLING_INFO_FAIL: (state, { payload }) => ({
    ...state,
    billingInfo: {
      ...state.billingInfo,
      requesting: false,
      error: payload.error
    }
  }),

  UPDATE_BILLING_REQUEST: (state) => ({
    ...state,
    updateBilling: {
      ...state.updateBilling,
      requesting: true
    }
  }),
  UPDATE_BILLING_SUCCESS: (state, { payload }) => ({
    ...state,
    updateBilling: {
      ...state.updateBilling,
      requesting: false,
      result: payload.data,
      error: null
    },
    billingInfo: {
      ...state.billingInfo,
      requesting: false,
      result: payload.data
    }
  }),
  UPDATE_BILLING_FAIL: (state, { payload }) => ({
    ...state,
    updateBilling: {
      ...state.updateBilling,
      requesting: false,
      error: payload.error
    }
  }),

  ADD_BILLING_AMOUNT_REQUEST: (state) => ({
    ...state,
    addAmount: {
      ...state.addAmount,
      requesting: true
    }
  }),
  ADD_BILLING_AMOUNT_SUCCESS: (state, { payload }) => ({
    ...state,
    addAmount: {
      ...state.addAmount,
      requesting: false,
      result: payload.data,
      error: null
    },
    billingInfo: {
      ...state.billingInfo,
      requesting: false,
      result: payload.data
    }
  }),
  ADD_BILLING_AMOUNT_FAIL: (state, { payload }) => ({
    ...state,
    addAmount: {
      ...state.addAmount,
      requesting: false,
      error: payload.error
    }
  }),

  SET_CARD_INVALID_REQUEST: (state) => ({
    ...state,
    cardInvalid: {
      ...state.cardInvalid,
      requesting: true
    }
  }),
  SET_CARD_INVALID_SUCCESS: (state, { payload }) => ({
    ...state,
    cardInvalid: {
      ...state.cardInvalid,
      requesting: false,
      result: payload.data,
      error: null
    },
    billingInfo: {
      ...state.billingInfo,
      requesting: false,
      result: payload.data
    }
  }),
  SET_CARD_INVALID_FAIL: (state, { payload }) => ({
    ...state,
    cardInvalid: {
      ...state.cardInvalid,
      requesting: false,
      error: payload.error
    }
  }),

  APPROVE_CHARGE_REQUEST: (state) => ({
    ...state,
    approveCharge: {
      ...state.approveCharge,
      requesting: true
    }
  }),
  APPROVE_CHARGE_SUCCESS: (state, { payload }) => ({
    ...state,
    approveCharge: {
      ...state.approveCharge,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  APPROVE_CHARGE_FAIL: (state, { payload }) => ({
    ...state,
    approveCharge: {
      ...state.approveCharge,
      requesting: false,
      error: payload.error
    }
  }),
  SET_ADD_BILLING_USER_ID: (state, { payload }) => ({
    ...state,
    addBillingUserId: payload
  }),
  SET_VISIBLE_ADD_AMOUNT: (state, { payload }) => ({
    ...state,
    visibleAddAmount: payload
  }),
}, initialState);

export default billing;
