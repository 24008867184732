import {
  find,
  floor,
  get,
  parseInt,
  times,
  toNumber
} from 'lodash';
import moment from 'moment';

export const getView = (width) => {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
};

export const getFileFromType = (data, type) => {
  return find(data, { type });
};

export const convertDurationToMinute = (value, notShowMinute) => {
  const minute = floor(value / 60, 0);
  const seconds = floor(value % 60, 0);
  if (notShowMinute) {
    return `${minute < 10 ? `${minute}` : minute}`;
  }
  // return `${minute < 10 ? `0${minute}` : minute}:${seconds < 10 ? `0${seconds}` : seconds}`;
  return `${minute < 10 ? `${minute}` : minute}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const createDefaultUploadFile = (data, isSoundFile) => {
  let name = get(data, 'name');
  let url = get(data, 'url');
  if (!isSoundFile) {
    const imageData = getFileFromType(data, 'medium');
    name = get(imageData, 'name');
    url = get(imageData, 'url');
  }

  // uid must be string number
  return [{
    uid: `${moment()
      .valueOf()}`, name, url, status: 'done'
  }];
};

export const validateEmail = (email) => {
  const regexCheckEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/i;
  return regexCheckEmail.test(String(email)
    .toLowerCase());
};

export const validateNumber = value => {
  if (typeof value === 'number') {
    return true;
  }

  const regexCheckNumber = /^\d+$/i;
  return regexCheckNumber.test(String(value)
    .toLowerCase());
};

export const rbg2hex = (colorval) => {
  const parts = colorval.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  delete (parts[0]);
  for (let i = 1; i <= 3; ++i) {
    parts[i] = parseInt(parts[i])
      .toString(16);
    if (parts[i].length === 1) {
      parts[i] = '0' + parts[i];
    }
  }
  return parts.join('');
};

export const extractEmailStore = text => {
  let emails = text ? text.split(',') : [];
  emails = emails.map(email => {
    const data = email.match(/(?:"?([^"]*)"?\s)?(?:<?(.+@[^>]+)>?)/i);
    if (data && data.length) {
      return {
        name: data[1] || '',
        email: data[2] || ''
      };
    }
    return {};
  });
  emails = emails.filter(item => Boolean(item.email));
  return emails;
};

export const formatCurrency = (currency, fixed = 2) => {
  if (!currency) {
    return 0;
  }
  currency = toNumber(currency);
  if (typeof currency === 'number') {
    return String(fixedFloat(currency, fixed))
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    // return currency.toFixed(fixed).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }
  return currency
    // eslint-disable-next-line
    .replace(/[(a-zA-Z)\s\_\,\-]+/g, '')
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

const fixedFloat = (input, decimals) => {
  const arr = input.toString()
    .split('.');
  if (arr.length === 1) {
    return decimals === 0 ? input : [input, '.', times(decimals, () => '0')
      .join('')].join('');
  }
  const int = arr[0];
  const max = arr[1].length;
  const dec = arr[1].substr(0, decimals > max ? max : decimals);
  return decimals === 0
    ? int
    : [int, '.', dec.length >= decimals ? dec : (dec + times(decimals - dec.length, () => '0')
      .join(''))].join('');
};

export const getQueryParam = (name, defaultData = '') => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name) || defaultData;
};

export const prepareUserInfoEmails = data => {
  return data.map(user => {
    return {
      id: user.id,
      display: `${user.firstName} ${user.lastName} <${user.email}>`
    };
  });
};
// edit

export const getErrorMessage = error =>
  get(error, 'data.message',
    get(error, 'data.message.0',
      get(error, 'data.error.message',
        '')));

export const isObjectId = str => /^[0-9a-fA-F]{24}$/.test(str);

export const phoneRegExp = /^(?=(?:\D*\d){10,15}\D*$)\+?[0-9]{1,3}[\s-]?(?:\(0?[0-9]{1,5}\)|[0-9]{1,5})[-\s]?[0-9][\d\s-]{5,7}\s?(?:x[\d-]{0,4})?$/;

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = (phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
}

export const DEFAULT_BILLING_AMOUNT = {
  TRANSACTION: 50,
  DOWNLOAD: 5
};

export const BILLING_TYPE = {
  TRIAL: 'TRIAL',
  CHARGE: 'CHARGE',
  DOWNLOAD_DOCUMENT: 'DOWNLOAD_DOCUMENT',
  CREATE_TRANSACTION: 'CREATE_TRANSACTION',
};
