import { get } from 'lodash';
import http from '../../../services/http';

export default class User {
  static login(body) {
    return http.post('/users/login', body);
  }

  static signUp(body) {
    return http.post('/users', body);
  }

  static fetchUserDocuments(userId) {
    return http.get(`/users/${userId}/documents`);
  }

  static sendMail(userId, body) {
    return http.post(`/users/${userId}/send-mail`, body);
  }

  static fetchMailByTransaction(userId) {
    return http.get(`/users/${userId}/mail-by-transaction`);
  }

  static updateUserInformation(userId, body) {
    return http.patch(`/users/${userId}`, body);
  }

  static updateUserPassword(body) {
    return http.post('/users/change-password', body);
  }

  static fetchUserInformation(userId) {
    return http.get(`/users/${userId}`, {
      params: {
        filter: {
          include: ['company', 'staffOf']
        }
      }
    });
  }

  static fetchAllUser(search, page, limit, spec) {
    const role = get(spec, 'role');
    const companyId = get(spec, 'companyId');
    const where = role === 'manage' ? {
      and: [
        {
          or: [
            { companyId },
            { companyId: { inq: [null, false, ''] } }
          ]
        },
        {
          or: [
            { firstName: { regexp: new RegExp(search, 'i').toString() } },
            { lastName: { regexp: new RegExp(search, 'i').toString() } },
            { email: { regexp: new RegExp(search, 'i').toString() } },
            { secondEmail: { regexp: new RegExp(search, 'i').toString() } }
          ]
        }
      ]
    } : {
      or: [
        { firstName: { regexp: new RegExp(search, 'i').toString() } },
        { lastName: { regexp: new RegExp(search, 'i').toString() } },
        { email: { regexp: new RegExp(search, 'i').toString() } },
        { secondEmail: { regexp: new RegExp(search, 'i').toString() } }
      ]
    };
    const params = {
      filter: {
        fields: [
          'id',
          'numberId',
          'firstName',
          'lastName',
          'email',
          'secondEmail',
          'companyId',
          'banned'
        ],
        include: 'company',
        where,
        order: 'createdAt DESC'
      },
      page,
      limit
    };
    return http.get('/users', {
      params
    });
  }

  static fetchUsers(params) {
    return http.get('/users/all', {
      params
    });
  }

  static setRoleForUser(body) {
    return http.post('/users/admin-user-role', body);
  }

  static adminChangeUserPassword(body) {
    return http.post('/users/change-user-password', body);
  }

  static fetchUnreadNotifications(userId) {
    return http.get(`/users/${userId}/notifications`, {
      params: {
        filter: {
          where: {
            userId,
            isRead: false
          }
        }
      }
    });
  }

  static readNotifications(transactionId) {
    return http.post(`/Notifications/mark-read/${transactionId}`);
  }

  static addCompany(userId, companyId, staffOfUserId, role) {
    return http.patch(`/users/${userId}`, { companyId, staffOfUserId, role });
  }

  static bannedUser(body) {
    return http.post('/users/banned', body);
  }

  static unbannedUser(body) {
    return http.post('/users/unbanned', body);
  }
}


