import { handleActions } from 'redux-actions';

const initialState = {
  jobs: {
    requesting: false,
    result: [],
    error: null
  },
  customerServices: {
    requesting: false,
    result: [],
    error: null
  },
  updateCus: {
    requesting: false,
    result: null,
    error: null
  },
  updateCusPresort: {
    requesting: false,
    result: null,
    error: null
  },
  print: {
    requesting: false,
    result: null,
    error: null
  },
  process: {
    requesting: false,
    result: null,
    error: null
  }
};

export const job = handleActions({
  FETCH_JOBS_REQUEST: (state) => ({
    ...state,
    jobs: {
      ...state.jobs,
      requesting: true,
      error: null
    }
  }),
  FETCH_JOBS_SUCCESS: (state, { payload }) => ({
    ...state,
    jobs: {
      ...state.jobs,
      requesting: false,
      result: payload.data
    }
  }),
  FETCH_JOBS_FAIL: (state, { payload }) => ({
    ...state,
    jobs: {
      ...state.jobs,
      requesting: false,
      error: payload.error
    }
  }),
  FETCH_CUSTOMER_SERVICES_REQUEST: (state) => ({
    ...state,
    customerServices: {
      ...state.customerServices,
      requesting: true,
      error: null
    }
  }),
  FETCH_CUSTOMER_SERVICES_SUCCESS: (state, { payload }) => ({
    ...state,
    customerServices: {
      ...state.customerServices,
      requesting: false,
      result: payload.data
    }
  }),
  FETCH_CUSTOMER_SERVICES_FAIL: (state, { payload }) => ({
    ...state,
    customerServices: {
      ...state.customerServices,
      requesting: false,
      error: payload.error
    }
  }),
  UPDATE_CUSTOMER_SERVICES_REQUEST: (state) => ({
    ...state,
    updateCus: {
      ...state.updateCus,
      requesting: true,
      error: null
    }
  }),
  UPDATE_CUSTOMER_SERVICES_SUCCESS: (state, { payload }) => ({
    ...state,
    updateCus: {
      ...state.updateCus,
      requesting: false,
      result: payload.data
    }
  }),
  UPDATE_CUSTOMER_SERVICES_FAIL: (state, { payload }) => ({
    ...state,
    updateCus: {
      ...state.updateCus,
      requesting: false,
      error: payload.error
    }
  }),
  UPDATE_CUSTOMER_SERVICES_FROM_PRESORT_REQUEST: (state) => ({
    ...state,
    updateCusPresort: {
      ...state.updateCusPresort,
      requesting: true,
      error: null
    }
  }),
  UPDATE_CUSTOMER_SERVICES_FROM_PRESORT_SUCCESS: (state, { payload }) => ({
    ...state,
    updateCusPresort: {
      ...state.updateCusPresort,
      requesting: false,
      result: payload.data
    }
  }),
  UPDATE_CUSTOMER_SERVICES_FROM_PRESORT_FAIL: (state, { payload }) => ({
    ...state,
    updateCusPresort: {
      ...state.updateCusPresort,
      requesting: false,
      error: payload.error
    }
  }),
  JOB_PRINT_REQUEST: (state) => ({
    ...state,
    print: {
      ...state.print,
      requesting: true,
      error: null
    }
  }),
  JOB_PRINT_SUCCESS: (state, { payload }) => ({
    ...state,
    print: {
      ...state.print,
      requesting: false,
      result: payload.data
    }
  }),
  JOB_PRINT_FAIL: (state, { payload }) => ({
    ...state,
    print: {
      ...state.print,
      requesting: false,
      error: payload.error
    }
  }),
  PROCESS_UPDATE_STATUS_REQUEST: (state) => ({
    ...state,
    process: {
      ...state.process,
      requesting: true,
      error: null
    }
  }),
  PROCESS_UPDATE_STATUS_SUCCESS: (state, { payload }) => ({
    ...state,
    process: {
      ...state.process,
      requesting: false,
      result: payload.data
    }
  }),
  PROCESS_UPDATE_STATUS_FAIL: (state, { payload }) => ({
    ...state,
    process: {
      ...state.process,
      requesting: false,
      error: payload.error
    }
  })
}, initialState);

export default job;
