import { handleActions } from 'redux-actions';
import { get } from 'lodash';

const initialState = {
  documentTypes: {
    result: [],
    error: null,
    requesting: false
  },
  states: {
    result: [],
    error: null,
    requesting: false
  },
  documentSets: {
    result: [],
    error: null,
    requesting: false
  },
  uploadDocument: {
    result: null,
    error: null,
    requesting: false
  },
  propertyDocuments: {
    result: null,
    error: null,
    requesting: false
  },
  documentPages: {
    result: null,
    error: null,
    requesting: false
  },
  saveDocumentIndex: {
    result: null,
    error: null,
    requesting: false
  },
  insertPagesToDocument: {
    result: null,
    error: null,
    requesting: false
  },
  exportDocument: {
    result: null,
    error: null,
    requesting: false
  },
  editDocument: {
    result: null,
    error: null,
    requesting: false
  },
  deleteDocument: {
    result: null,
    error: null,
    requesting: false
  },
  editDocumentUserIndex: {
    result: null,
    error: null,
    requesting: false
  },
  mergePdfDocument: {
    result: null,
    error: null,
    requesting: false
  },
  downloadLink: {
    result: null,
    error: null,
    requesting: false
  },
  OCR: {
    result: null,
    error: null,
    requesting: false
  },
  documentReleased: {
    result: [],
    error: null,
    requesting: false
  },
  pageEdit: {
    result: null,
    error: null,
    requesting: false
  },
  deletePages: {
    result: null,
    error: null,
    requesting: false
  },
  documentItems: [],
  categories: [],
  fixedChecklist: {
    result: null,
    error: null,
    requesting: false
  },
  updateChecklist: {
    result: null,
    error: null,
    requesting: false
  },
  alertAck: {
    result: null,
    error: null,
    requesting: false
  }
};

export const document = handleActions({
  /** FETCH DOCUMENT TYPES **/
  FETCH_DOCUMENT_TYPES_REQUEST: (state) => ({
    ...state,
    documentTypes: {
      ...state.documentTypes,
      requesting: true,
      error: null
    }
  }),
  FETCH_DOCUMENT_TYPES_SUCCESS: (state, { payload }) => ({
    ...state,
    documentTypes: {
      ...state.documentTypes,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_DOCUMENT_TYPES_FAIL: (state, { payload }) => ({
    ...state,
    documentTypes: {
      ...state.documentTypes,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** FETCH STATES **/
  FETCH_STATES_REQUEST: (state) => ({
    ...state,
    states: {
      ...state.states,
      requesting: true,
      error: null
    }
  }),
  FETCH_STATES_SUCCESS: (state, { payload }) => ({
    ...state,
    states: {
      ...state.states,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_STATES_FAIL: (state, { payload }) => ({
    ...state,
    states: {
      ...state.states,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** FETCH DOCUMENT SETS **/
  FETCH_DOCUMENT_SETS_REQUEST: (state) => ({
    ...state,
    documentSets: {
      ...state.documentSets,
      requesting: true,
      error: null
    }
  }),
  FETCH_DOCUMENT_SETS_SUCCESS: (state, { payload }) => ({
    ...state,
    documentSets: {
      ...state.documentSets,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_DOCUMENT_SETS_FAIL: (state, { payload }) => ({
    ...state,
    documentSets: {
      ...state.documentSets,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),


  /** UPLOAD DOCUMENT **/
  UPLOAD_DOCUMENT_REQUEST: (state) => ({
    ...state,
    uploadDocument: {
      ...state.uploadDocument,
      requesting: true,
      error: null
    }
  }),
  UPLOAD_DOCUMENT_SUCCESS: (state, { payload }) => ({
    ...state,
    uploadDocument: {
      ...state.uploadDocument,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  UPLOAD_DOCUMENT_FAIL: (state, { payload }) => ({
    ...state,
    uploadDocument: {
      ...state.uploadDocument,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** FETCH DOCUMENT PAGES**/
  FETCH_DOCUMENTS_OF_PROPERTY_REQUEST: (state) => ({
    ...state,
    propertyDocuments: {
      ...state.propertyDocuments,
      requesting: true,
      error: null
    }
  }),
  FETCH_DOCUMENTS_OF_PROPERTY_SUCCESS: (state, { payload }) => ({
    ...state,
    propertyDocuments: {
      ...state.propertyDocuments,
      requesting: false,
      result: payload.data,
      error: null
    },
    categories: payload.categories
  }),
  FETCH_DOCUMENTS_OF_PROPERTY_FAIL: (state, { payload }) => ({
    ...state,
    propertyDocuments: {
      ...state.propertyDocuments,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** FETCH DOCUMENT PAGES**/
  FETCH_DOCUMENT_PAGES_REQUEST: (state) => ({
    ...state,
    documentPages: {
      ...state.documentPages,
      requesting: true,
      error: null
    }
  }),
  FETCH_DOCUMENT_PAGES_SUCCESS: (state, { payload }) => ({
    ...state,
    documentPages: {
      ...state.documentPages,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_DOCUMENT_PAGES_FAIL: (state, { payload }) => ({
    ...state,
    documentPages: {
      ...state.documentPages,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** SAEVE DOCUMENT INDEX **/
  SAVE_DOCUMENT_INDEX_REQUEST: (state) => ({
    ...state,
    saveDocumentIndex: {
      ...state.saveDocumentIndex,
      requesting: true,
      error: null
    }
  }),
  SAVE_DOCUMENT_INDEX_SUCCESS: (state, { payload }) => ({
    ...state,
    saveDocumentIndex: {
      ...state.saveDocumentIndex,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  SAVE_DOCUMENT_INDEX_FAIL: (state, { payload }) => ({
    ...state,
    saveDocumentIndex: {
      ...state.saveDocumentIndex,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** INSERT PAGES TO DOCUMENT **/
  INSERT_PAGES_TO_DOCUMENT_REQUEST: (state) => ({
    ...state,
    insertPagesToDocument: {
      ...state.insertPagesToDocument,
      requesting: true,
      error: null
    }
  }),
  INSERT_PAGES_TO_DOCUMENT_SUCCESS: (state, { payload }) => ({
    ...state,
    insertPagesToDocument: {
      ...state.insertPagesToDocument,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  INSERT_PAGES_TO_DOCUMENT_FAIL: (state, { payload }) => ({
    ...state,
    insertPagesToDocument: {
      ...state.insertPagesToDocument,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** EXPORT DOCUMENT FROM PAGES **/
  EXPORT_DOCUMENT_FROM_PAGES_REQUEST: (state) => ({
    ...state,
    exportDocument: {
      ...state.exportDocument,
      requesting: true,
      error: null
    }
  }),
  EXPORT_DOCUMENT_FROM_PAGES_SUCCESS: (state, { payload }) => ({
    ...state,
    exportDocument: {
      ...state.exportDocument,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  EXPORT_DOCUMENT_FROM_PAGES_FAIL: (state, { payload }) => ({
    ...state,
    exportDocument: {
      ...state.exportDocument,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** DELETE DOCUMENT **/
  DELETE_DOCUMENT_REQUEST: (state) => ({
    ...state,
    deleteDocument: {
      ...state.deleteDocument,
      requesting: true,
      error: null
    }
  }),
  DELETE_DOCUMENT_SUCCESS: (state, { payload }) => ({
    ...state,
    deleteDocument: {
      ...state.deleteDocument,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  DELETE_DOCUMENT_FAIL: (state, { payload }) => ({
    ...state,
    deleteDocument: {
      ...state.deleteDocument,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** EDIT DOCUMENT **/
  EDIT_DOCUMENT_REQUEST: (state) => ({
    ...state,
    editDocument: {
      ...state.editDocument,
      requesting: true,
      error: null
    }
  }),
  EDIT_DOCUMENT_SUCCESS: (state, { payload }) => ({
    ...state,
    editDocument: {
      ...state.editDocument,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  EDIT_DOCUMENT_FAIL: (state, { payload }) => ({
    ...state,
    editDocument: {
      ...state.editDocument,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** EDIT DOCUMENT **/
  EDIT_DOCUMENT_USER_INDEX_REQUEST: (state) => ({
    ...state,
    editDocumentUserIndex: {
      ...state.editDocumentUserIndex,
      requesting: true,
      error: null
    }
  }),
  EDIT_DOCUMENT_USER_INDEX_SUCCESS: (state, { payload }) => ({
    ...state,
    editDocumentUserIndex: {
      ...state.editDocumentUserIndex,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  EDIT_DOCUMENT_USER_INDEX_FAIL: (state, { payload }) => ({
    ...state,
    editDocumentUserIndex: {
      ...state.editDocumentUserIndex,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  GET_DOCUMENT_ITEMS: (state, { payload }) => ({
    ...state,
    documentItems: payload
  }),

  /** MERGE PDF DOCUMENT **/
  MERGE_PDF_DOCUMENT_REQUEST: state => ({
    ...state,
    mergePdfDocument: {
      ...state.mergePdfDocument,
      requesting: true,
      error: null
    }
  }),
  MERGE_PDF_DOCUMENT_SUCCESS: (state, { payload }) => ({
    ...state,
    mergePdfDocument: {
      ...state.mergePdfDocument,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  MERGE_PDF_DOCUMENT_FAIL: (state, { payload }) => ({
    ...state,
    mergePdfDocument: {
      ...state.mergePdfDocument,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** DOWNLOAD PDF DOCUMENT **/
  DOWNLOAD_DOCUMENT_REQUEST: state => ({
    ...state,
    downloadLink: {
      ...state.downloadLink,
      requesting: true,
      error: null
    }
  }),
  DOWNLOAD_DOCUMENT_SUCCESS: (state, { payload }) => ({
    ...state,
    downloadLink: {
      ...state.downloadLink,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  DOWNLOAD_DOCUMENT_FAIL: (state, { payload }) => ({
    ...state,
    downloadLink: {
      ...state.downloadLink,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** OCR **/
  TEXT_DETECTION_REQUEST: state => ({
    ...state,
    OCR: {
      ...state.OCR,
      requesting: true,
      error: null
    }
  }),
  TEXT_DETECTION_SUCCESS: (state, { payload }) => ({
    ...state,
    OCR: {
      ...state.OCR,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  TEXT_DETECTION_FAIL: (state, { payload }) => ({
    ...state,
    OCR: {
      ...state.OCR,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** RELEASE DOCUMENT **/
  RELEASE_DOCUMENTS_REQUEST: state => ({
    ...state,
    documentReleased: {
      ...state.documentReleased,
      requesting: true,
      error: null
    }
  }),
  RELEASE_DOCUMENTS_SUCCESS: (state, { payload }) => ({
    ...state,
    documentReleased: {
      ...state.documentReleased,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  RELEASE_DOCUMENTS_FAIL: (state, { payload }) => ({
    ...state,
    documentReleased: {
      ...state.documentReleased,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** DOWNLOAD_DOCUMENT_BY_CHECKED DOCUMENT **/
  DOWNLOAD_DOCUMENT_BY_CHECKED_REQUEST: state => ({
    ...state,
    downloadLink: {
      ...initialState.downloadLink,
      requesting: true,
      error: null
    }
  }),
  DOWNLOAD_DOCUMENT_BY_CHECKED_SUCCESS: (state, { payload }) => ({
    ...state,
    downloadLink: {
      ...state.downloadLink,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  DOWNLOAD_DOCUMENT_BY_CHECKED_FAIL: (state, { payload }) => ({
    ...state,
    downloadLink: {
      ...state.downloadLink,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** DOWNLOAD_DOCUMENT_BY_CHECKED DOCUMENT **/
  EDIT_PAGE_INFO_REQUEST: state => ({
    ...state,
    pageEdit: {
      ...initialState.pageEdit,
      requesting: true,
      error: null
    }
  }),
  EDIT_PAGE_INFO_SUCCESS: (state, { payload }) => ({
    ...state,
    pageEdit: {
      ...state.pageEdit,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  EDIT_PAGE_INFO_FAIL: (state, { payload }) => ({
    ...state,
    pageEdit: {
      ...state.pageEdit,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  /** SWITCH_DOCUMENT_AND_PAGE_REQUEST DOCUMENT **/
  SWITCH_DOCUMENT_AND_PAGE_REQUEST: state => ({
    ...state,
    switch: {
      ...initialState.switch,
      requesting: true,
      error: null
    }
  }),
  SWITCH_DOCUMENT_AND_PAGE_SUCCESS: (state, { payload }) => ({
    ...state,
    switch: {
      ...state.switch,
      requesting: false,
      result: true,
      error: null
    },
    propertyDocuments: {
      ...state.propertyDocuments,
      result: {
        ...get(state.propertyDocuments, 'result', {}),
        documents: payload.data
      }
    }
  }),
  SWITCH_DOCUMENT_AND_PAGE_FAIL: (state, { payload }) => ({
    ...state,
    switch: {
      ...state.switch,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  DELETE_PAGES_REQUEST: state => ({
    ...state,
    deletePages: {
      ...initialState.deletePages,
      requesting: true,
      error: null
    }
  }),
  DELETE_PAGES_SUCCESS: state => ({
    ...state,
    deletePages: {
      ...state.deletePages,
      requesting: false,
      result: true,
      error: null
    }
  }),
  DELETE_PAGES_FAIL: (state, { payload }) => ({
    ...state,
    deletePages: {
      ...state.deletePages,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  FIXED_CHECKLIST_REQUEST: state => ({
    ...state,
    fixedChecklist: {
      ...initialState.fixedChecklist,
      requesting: true,
      error: null
    }
  }),
  FIXED_CHECKLIST_SUCCESS: (state, { payload }) => ({
    ...state,
    fixedChecklist: {
      ...state.fixedChecklist,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FIXED_CHECKLIST_FAIL: (state, { payload }) => ({
    ...state,
    fixedChecklist: {
      ...state.fixedChecklist,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  UPDATE_CHECKLIST_REQUEST: state => ({
    ...state,
    updateChecklist: {
      ...initialState.updateChecklist,
      requesting: true,
      error: null
    }
  }),
  UPDATE_CHECKLIST_SUCCESS: (state, { payload }) => ({
    ...state,
    updateChecklist: {
      ...state.updateChecklist,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  UPDATE_CHECKLIST_FAIL: (state, { payload }) => ({
    ...state,
    updateChecklist: {
      ...state.updateChecklist,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),

  ALERT_ACKNOWLEDGE_REQUEST: state => ({
    ...state,
    alertAck: {
      ...initialState.alertAck,
      requesting: true,
      error: null
    }
  }),
  ALERT_ACKNOWLEDGE_SUCCESS: (state, { payload }) => ({
    ...state,
    alertAck: {
      ...state.alertAck,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  ALERT_ACKNOWLEDGE_FAIL: (state, { payload }) => ({
    ...state,
    alertAck: {
      ...state.alertAck,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  SET_TRANSACTION_STATUS: (state, { payload }) => ({
    ...state,
    propertyDocuments: {
      ...state.propertyDocuments,
      result: {
        ...state.propertyDocuments.result,
        status: payload
      },
      error: null
    }
  }),

  /** INITIAL USER DATA **/
  SET_INITIAL_USER_DATA: () => initialState
}, initialState);

export default document;
