import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Popover } from 'antd';
import { get } from 'lodash';

import ModalMailBox from '../ModalMailbox';
import IntlMessages from '../../components/Isomorphic/utility/intlMessages';
import { fetchUnreadNotifications } from '../../store/modules/User/action';
import { timeDifference } from '../../helpers/utility';

import TopbarDropdownWrapper from './topbarDropdown.style';

class TopbarNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      showMailBox: false,
      transactionId: ''
    };
  }

  componentDidMount() {
    const { userLogin, dispatchFetchUnreadNotifications } = this.props;

    const userId = get(userLogin, 'result.id', '');
    dispatchFetchUnreadNotifications(userId);
  }

  initData = () => {
    const { notifications } = this.props;
    const mailbox = get(notifications, 'result.mailbox', []);

    const notificationsBadge = mailbox.map((ele, index) => ({
      id: index,
      name: ele.description,
      createdAt: ele.createdAt,
      notification: ele.title,
      transactionId: ele.transactionId
    }));

    return notificationsBadge;
  };

  renderContent = () => {
    return (
      <TopbarDropdownWrapper className="topbarNotification">
        <div className="isoDropdownHeader">
          <h3>
            <IntlMessages id="sidebar.notification" />
          </h3>
        </div>

        <div className="isoDropdownBody">
          {this.initData()
            .map(notification => (
              <div
                className="isoDropdownListItem"
                key={notification.id}
                onClick={this.handleShowMailBox(notification.transactionId)}
              >
                <div className="isoListItemContent">
                  <h5>{notification.name}</h5>
                  <p>{timeDifference(notification.createdAt)}</p>
                </div>
                <p>{notification.notification}</p>
              </div>
            ))}
        </div>
      </TopbarDropdownWrapper>
    );
  };

  handleVisibleChange = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  handleShowMailBox = (id) => () => {
    this.setState({
      visible: false,
      showMailBox: true,
      transactionId: id
    });
  };

  handleCancelMailBox = () => {
    this.setState({ showMailBox: false });
  };

  /* eslint-disable jsx-a11y/anchor-is-valid */
  render() {
    const { visible, showMailBox, transactionId } = this.state;
    const { userLogin } = this.props;

    const userId = get(userLogin, 'result.id', '');
    const secondEmail = get(userLogin, 'result.secondEmail', '');
    const email = get(userLogin, 'result.email');

    return (
      <div>
        <Popover
          content={this.renderContent()}
          trigger="click"
          visible={visible}
          onVisibleChange={this.handleVisibleChange}
          placement="bottomLeft"
        >
          <div className="isoIconWrapper">
            <i
              className="ion-android-notifications"
              style={{ color: 'rgb(50, 51, 50)' }}
            />
            {
              this.initData().length > 0 && (
                <span>{this.initData().length}</span>
              )
            }
          </div>
        </Popover>

        {
          showMailBox && (
            <ModalMailBox
              userId={userId}
              visible={showMailBox}
              handleCancel={this.handleCancelMailBox}
              onSubmit={this.handleOkMailBox}
              secondEmail={secondEmail}
              email={email}
              transactionId={transactionId}
            />
          )
        }
      </div>
    );
  }
}

export default connect(
  state => ({
    userLogin: state.user.login,
    notifications: state.user.notifications
  }),
  dispatch => bindActionCreators({
    dispatchFetchUnreadNotifications: fetchUnreadNotifications
  }, dispatch)
)(TopbarNotification);
