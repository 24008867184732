import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Breadcrumb,
  Icon,
  Layout
} from 'antd';
import { bindActionCreators } from 'redux';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import {
  Redirect,
  Route,
  withRouter
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import {
  compact,
  get,
  join,
  last
} from 'lodash';
import pluralize from 'pluralize';
import $ from 'jquery';

import { toggleAll } from '../../store/modules/Common/action';
import {
  fetchMailByTransaction
  // fetchUnreadNotifications
} from '../../store/modules/User/action';

import Topbar from '../Topbar';
import Sidebar from '../Sidebar/Sidebar';

import http from '../../services/http';
// import SocketService from '../../services/socket';

import './global.css';
import AppHolder from './commonStyle';
import themes from '../../settings/themes';

import {
  siteConfig,
  themeConfig
} from '../../settings';

import AddBillingCardModal from '../../components/BillingCardModal';

window.$ = $;
window.jQuery = $;

const { Content, Footer } = Layout;

class App extends Component {
  constructor(props) {
    super(props);
    const { login } = props;
    const accessToken = get(login, 'result.accessToken', '');

    if (accessToken) {
      http.setAuthorizationHeader(accessToken);
    }
  }

  componentDidMount() {
    let script = document.createElement('script');
    script.src = process.env.PUBLIC_URL + '/js/jquery-ui/jquery-ui.min.js';
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement('script');
    script.src = process.env.PUBLIC_URL + '/js/image-resize.min.js';
    script.async = true;
    document.body.appendChild(script);
    const { login /* , fetchUnreadNotifications */ } = this.props;
    const userId = get(login, 'result.id', '');

    if (userId) {
      // fetchUnreadNotifications(userId);

      // const socketService = new SocketService();
      // socketService.getInstance(this.props.accessToken);
      // const fetchAfterReceivedEmail = () => {
      //   fetchUnreadNotifications(userId);
      // };
      // socketService.listenerNewEmail(fetchAfterReceivedEmail);
    }
  }

  capitalizeFirstLetter(string) {
    const newString = string.charAt(0)
      .toUpperCase() + string.slice(1);
    return join(newString.split('-'), ' ');
  }

  generateBreadcrumb = () => {
    const { path, location } = this.props;

    const splitPath = compact(path.split('/'));
    const splitLocation = compact(location.pathname.split('/'));
    let breadcrumb = splitPath.map((item, index) => {
      let name = this.capitalizeFirstLetter(item);
      const urlArray = splitLocation.slice(0, index + 1);
      const url = `/${urlArray.join('/')}`;
      if (item.includes(':')) {
        if (splitPath.length >= 3 && index === 1) {
          return false;
        }
        const parentName = this.capitalizeFirstLetter(splitPath[index - 1]);
        name = `${pluralize.singular(parentName)} detail`;
      }

      return { name, url };
    });
    const lastObj = last(breadcrumb);
    if (lastObj.name === 'Documents') {
      breadcrumb = [breadcrumb[0], breadcrumb[breadcrumb.length - 1]];
    }
    return compact(breadcrumb);
  };

  render() {
    const { url } = this.props.match;
    const { height, history, isAuthenticated } = this.props;
    const appHeight = window.innerHeight;
    const breadcrumb = this.generateBreadcrumb();

    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder>
          <Layout style={{ height: appHeight }}>
            <Debounce time="1000" handler="onResize">
              <WindowResizeListener
                onResize={windowSize =>
                  this.props.toggleAll(
                    windowSize.windowWidth,
                    windowSize.windowHeight
                  )
                }
              />
            </Debounce>
            <Topbar history={history} url={url} />
            <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
              <Sidebar url={url} />
              <Layout
                className="isoContentMainLayout"
                style={{
                  height
                }}
              >
                <Content
                  className="isomorphicContent"
                  style={{
                    padding: '70px 0 0',
                    flexShrink: '0',
                    background: '#f1f3f6',
                    position: 'relative'
                  }}
                >
                  {
                    isAuthenticated && (
                      <Breadcrumb className="app-breadcrumb">
                        <Breadcrumb.Item className="item-breadcrumb" href="/">
                          <Icon
                            type={'home'}
                          />
                        </Breadcrumb.Item>

                        {breadcrumb.map((item, index) => (
                          <Breadcrumb.Item key={index}>
                            <a className="item-breadcrumb" href={item.url}>{item.name}</a>
                          </Breadcrumb.Item>
                        ))}
                      </Breadcrumb>
                    )
                  }

                  {this.props.children}
                </Content>
                <Footer
                  style={{
                    background: '#ffffff',
                    textAlign: 'center',
                    borderTop: '1px solid #ededed'
                  }}
                >
                  {siteConfig.footerText}
                  <span id="siteseal"><script async type="text/javascript" src="https://seal.starfieldtech.com/getSeal?sealID=3VtONFKLcFHMog8pNNFW5mTZXFbFUr37z5SOyUkAtsS9CLVm90QR7irGJHhn"></script></span>
                </Footer>
                <AddBillingCardModal />
              </Layout>
            </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

const AppRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  const { location } = rest;
  const { pathname } = location;
  let keepComponent = false;
  if (pathname.indexOf('documents-public') !== -1) {
    keepComponent = true;
  }
  return (
    <Route
      {...rest}
      render={props => {
        return (
          (isAuthenticated || keepComponent) ? (
            <App {...rest} isAuthenticated={isAuthenticated}>
              <Component {...props} />
            </App>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        );
      }}
    />
  );
};

export default withRouter(connect(
  state => ({
    login: state.user.login,
    height: state.common.height,
    accessToken: get(state, 'user.login.result.accessToken'),
    isAuthenticated: Boolean(get(state, 'user.login.result.accessToken'))
  }),
  dispatch => bindActionCreators({
    toggleAll,
    fetchMailByTransaction
    // fetchUnreadNotifications
  }, dispatch)
)(AppRoute));
