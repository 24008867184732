import React from 'react';
import {
  Button,
  Form,
  Modal,
} from 'antd';
import {
  Field,
  withFormik
} from 'formik';
import * as Yup from 'yup';

import FieldValidation from '../Form/FieldValidation';
import Wrapper, { ModalFooterWrapper } from './styles';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../utils/common';
import LoadingFullContainer from '../LoadingFullContainer';

const validationSchema = Yup.object()
  .shape({
    amount: Yup.number().min(20).required('Required.'),
  });

const summaryInfoLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 7 },
    xl: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 17 },
    xl: { span: 19 }
  }
};

const AddBillingForm = props => {
  const {
    visible,
    requesting,
    handleCancel,
    handleSubmit,
    loading
  } = props;

  const amount = useSelector(state => state?.billing?.billingInfo?.result?.amount ?? 0);
  const cardNameStore = useSelector(state => state?.billing?.billingInfo?.result?.cardName ?? '');
  const cardNumberStore = useSelector(state => state?.billing?.billingInfo?.result?.cardNumber ?? '');
  const cardExpiryStore = useSelector(state => state?.billing?.billingInfo?.result?.cardExpiry ?? '');

  return (
    <Modal
      title="Add Credits"
      visible={visible}
      onCancel={handleCancel}
      footer={
        <ModalFooterWrapper>
          <div className="footerBtn">
            <Button
              type="primary"
              onClick={handleSubmit}
              loading={requesting}
            >
              {requesting ? 'Adding' : 'Add'}
            </Button>
          </div>
        </ModalFooterWrapper>
      }
    >
      <Wrapper>
        {
          loading ? (
            <LoadingFullContainer />
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Item {...summaryInfoLayout} label="Credits" labelAlign="left" style={{ marginBottom: 0, fontWeight: 'bold' }}>
                <p style={{ marginBottom: 0 }}><b>${formatCurrency(amount)}</b></p>
              </Form.Item>
              <Form.Item {...summaryInfoLayout} label="Name" labelAlign="left" style={{ marginBottom: 0, fontWeight: 'bold' }}>
                <p style={{ marginBottom: 0 }}><b>{cardNameStore}</b></p>
              </Form.Item>
              <Form.Item {...summaryInfoLayout} label="Credit Card" labelAlign="left" style={{ marginBottom: 0, fontWeight: 'bold' }}>
                <p style={{ marginBottom: 0 }}><b>{cardNumberStore} ({cardExpiryStore})</b></p>
              </Form.Item>
              <Field
                component={FieldValidation}
                formItemLayout={{
                  style: { margin: '0 0 10px 0' }
                }}
                placeholder="Min $20"
                name={'amount'}
                type="number"
              />
            </Form>
          )
        }
      </Wrapper>
    </Modal>
  );
};

export default withFormik({
  validationSchema,
  validateOnChange: false,
  enableReinitialize: true,
  // Use mapPropsToValues to prevent warning of controlled component
  // Or set initial data from container passing through props
  mapPropsToValues: (props) => {
    return {
    };
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setSubmitting(false);
  }
})(AddBillingForm);

