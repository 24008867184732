import { handleActions } from 'redux-actions';

const initialState = {
  orders: {
    requesting: false,
    result: [],
    error: null
  }
};

export const order = handleActions({
  FETCH_ORDERS_REQUEST: (state) => ({
    ...state,
    orders: {
      ...state.orders,
      requesting: true,
      error: null
    }
  }),
  FETCH_ORDERS_SUCCESS: (state, { payload }) => ({
    ...state,
    orders: {
      ...state.orders,
      requesting: false,
      result: payload.data
    }
  }),
  FETCH_ORDERS_FAIL: (state, { payload }) => ({
    ...state,
    orders: {
      ...state.orders,
      requesting: false,
      error: payload.error
    }
  })
}, initialState);

export default order;
