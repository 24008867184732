import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Modal } from 'antd';
import { get } from 'lodash';

import ComposeMail from '../Isomorphic/mail/composeMail.js';

class ToggleEmailBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleBodyMail: false,
      height: null,
      visible: false,
      composeMail: false
    };
  }

  renderModalTitle = () => {
    const {
      email,
      mailData,
      secondEmail
    } = this.props;

    const to = get(mailData, 'to', '');
    const from = get(mailData, 'from', '');

    let replyTo = '';

    if (from === email || from === secondEmail) {
      replyTo = to;
    } else if (from !== email || from !== secondEmail) {
      replyTo = from;
    } else {
      return replyTo;
    }

    return (
      <span style={{ color: '#4482FF' }}>Reply to {replyTo}</span>
    );
  };

  onToggleBodyMail = () => {
    const { isToggleBodyMail } = this.state;

    this.setState({ isToggleBodyMail: !isToggleBodyMail });
  };

  handleShowMailBox = () => {
    this.setState({
      visible: true
    });
  };

  handleOkMailBox = () => {
    this.setState({ visible: false });
  };

  handleCancelMailBox = () => {
    this.setState({ visible: false });
  };

  handleChangeComposeMail = (value) => {
    this.setState({ composeMail: value });
  };

  handleSuccessSendMail = value => {
    this.setState({ visible: value });
  };

  render() {
    const { visible } = this.state;
    const {
      email,
      mailData,
      sendMail,
      secondEmail,
      isRequesting,
      selectedMail,
      withOutContext
    } = this.props;

    const to = get(mailData, 'to', '');
    const from = get(mailData, 'from', '');
    const subject = get(mailData, 'subject', '');
    const replyId = get(mailData, 'messageId', '');
    let body = get(mailData, 'body', '');
    if (body.indexOf('html') !== -1) {
      body = /<body.*?>([\s\S]*)<\/body>/.exec(body)[1];
    }

    const replyTO = (from === email || from === secondEmail) ? to : from;
    let emailFrom = replyTO.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    emailFrom = emailFrom ? emailFrom[0] : '';
    let replyCC = to.split(',')
      .map(item => {
        const itemEmail = item.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
        if (
          itemEmail && (
            itemEmail === itemEmail[0]
            || emailFrom === itemEmail[0]
            || email === itemEmail[0]
            || secondEmail === itemEmail[0]
          )
        ) {
          return null;
        }

        return item;
      });
    replyCC = replyCC.filter(item => Boolean(item));
    replyCC = replyCC ? replyCC.join(',') : '';

    return (
      <div>
        <div style={{ float: 'right' }} onClick={this.handleShowMailBox}>
          <span style={{ color: '#40a9ff', textDecoration: 'underline', cursor: 'pointer' }}>reply</span>
        </div>

        <span style={{ fontWeight: 'bold' }}>{subject}</span>
        <div style={{ backgroundColor: 'rgb(235,235,235)', height: 1, marginTop: 5, marginBottom: 5 }} />

        <div ref="inner" id="bodyMails" style={{ minHeight: 40 }}>
          {ReactHtmlParser(body)}
        </div>

        {
          visible && (
            <Modal
              title={this.renderModalTitle()}
              width="70%"
              visible={visible}
              onCancel={this.handleCancelMailBox}
              bodyStyle={{ padding: 20 }}
              footer={null}
            >
              <ComposeMail
                email={email}
                subject={subject}
                replyId={replyId}
                sendMail={sendMail}
                selectedMail={selectedMail}
                secondEmail={secondEmail}
                isRequesting={isRequesting}
                replyMailTO={replyTO}
                replyMailCC={replyCC}
                changeComposeMail={this.handleCancelMailBox}
                handleSuccessSendMail={this.handleSuccessSendMail}
                withOutContext={withOutContext}
                body={body}
                mailData={mailData}
              />
            </Modal>
          )
        }
      </div>
    );
  }
}

export default ToggleEmailBody;
