/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Button, Layout, Menu } from 'antd';

import TopbarUser from './topbarUser';
import TopbarNotification from './topbarNotification';

import { toggleCollapsed } from '../../store/modules/Common/action';
import { themeConfig } from '../../settings';
import themes from '../../settings/themes';

import TopbarWrapper from './topbar.style';
import { get } from 'lodash';

const { SubMenu } = Menu;
const { Header } = Layout;
const customizedTheme = themes[themeConfig.theme];

const stripTrailingSlash = str => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Topbar extends Component {
  renderMenuItem = (option) => {
    const url = stripTrailingSlash(this.props.url);
    const children = option.children;

    if (children) {
      return (
        <SubMenu key={option.key} title={option.label}>
          {
            children.map((item, i) => {
              const linkTo = item.withoutDashboard
              ? `/${item.key}`
              : `${url}/${item.key}`;
              return (
              <Menu.Item key={i}>
                <Link to={linkTo}>
                  {item.label}
                </Link>
              </Menu.Item>
              )
            })
          }
        </SubMenu>
      )
    }

    const linkTo = option.key ? `${url}/${option.key}` : `${url}`;

    return (
      <Menu.Item key={option.label}>
        <Link to={linkTo}>
          {option.label}
        </Link>
      </Menu.Item>
    )
  }

  render() {
    const { toggleCollapsed, history, url, isAuthenticated, isAdmin } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.topBarBackgroundColor,
      position: 'fixed',
      width: '100%',
      height: 70
    };

    const logo = `${process.env.PUBLIC_URL}/images/TitleLogicsLogo.svg`;

    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
          }
        >
          <div className="isoLeft">
            <div className="menu-container">
              <a href={isAuthenticated ? url : 'https://titlelogics.com/'}>
                <img
                  className="title-picture"
                  src={logo}
                  alt="flag"
                  sizes="contain"
                  href={isAuthenticated ? url : 'https://titlelogics.com/'}
                />
              </a>
              {/* <Menu mode="horizontal">
                {options.map((option, index) =>
                  this.renderMenuItem(option)
                )}
              </Menu> */}
            </div>

            <button
              className={
                collapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'
              }
              style={{ color: customizedTheme.textColor }}
              onClick={toggleCollapsed}
            />
          </div>

          <div className="isoCenter">
            {/*<i className="ion-flash" />*/}
          </div>

          {
            isAuthenticated && (
              <ul className="isoRight">
                {
                  isAdmin && (
                    <li
                      onClick={() => this.setState({ selectedItem: 'orders' })}
                      className="isoNotify"
                    >
                      <Link to="/vouchers">
                        <Button type="primary" className="btn-transaction" style={{ marginRight: 10 }}>
                          Vouchers
                        </Button>
                      </Link>
                      <Link to="/titlebell-orders">
                        <Button type="primary" className="btn-transaction">
                          Titlebell Orders
                        </Button>
                      </Link>

                    </li>
                  )
                }

                <li
                  onClick={() => this.setState({ selectedItem: 'notification' })}
                  className="isoNotify"
                >
                  <TopbarNotification />
                </li>

                <li
                  onClick={() => this.setState({ selectedItem: 'user' })}
                  className="isoUser"
                >
                  <TopbarUser history={history} url={url} />
                </li>
              </ul>
            )
          }
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    collapsed: state.common.collapsed,
    openDrawer: state.common.openDrawer,
    isAuthenticated: Boolean(get(state, 'user.login.result.accessToken')),
    isAdmin: get(state, 'user.login.result.role') === 'admin'
  }),
  dispatch => bindActionCreators({
    toggleCollapsed
  }, dispatch)
)(Topbar);
