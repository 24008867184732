import { handleActions } from 'redux-actions';

const initialState = {
  localities: {
    requesting: false,
    result: [],
    error: null
  }
};

export const locality = handleActions({
  FETCH_LOCALITIES_REQUEST: (state) => ({
    ...state,
    localities: {
      ...state.localities,
      requesting: true,
      error: null
    }
  }),
  FETCH_LOCALITIES_SUCCESS: (state, { payload }) => ({
    ...state,
    localities: {
      ...state.localities,
      requesting: false,
      result: payload.data
    }
  }),
  FETCH_LOCALITIES_FAIL: (state, { payload }) => ({
    ...state,
    localities: {
      ...state.localities,
      requesting: false,
      error: payload.error
    }
  })
}, initialState);

export default locality;
