import { handleActions } from 'redux-actions';

const initialState = {
  transactions: {
    result: [],
    requesting: false,
    error: null
  },
  transactionInfo: {
    result: null,
    requesting: false,
    error: null
  },
  addTransaction: {
    result: null,
    requesting: false,
    error: null
  },
  editTransaction: {
    result: null,
    requesting: false,
    error: null
  },
  staffs: {
    result: [],
    error: null,
    requesting: false
  },
  managers: {
    result: [],
    error: null,
    requesting: false
  },
  assignStaff: {
    result: null,
    error: null,
    requesting: false
  },
  transactionIds: [],
  setStatusTransaction: {
    result: null,
    error: null,
    requesting: false
  },
  quickAdd: {
    result: null,
    error: null,
    requesting: false
  },
  editQuickAdd: {
    result: null,
    error: null,
    requesting: false
  },
  assignJob: {
    result: null,
    error: null,
    requesting: false
  },
  accountStatus: {
    result: null,
    error: null,
    requesting: false
  },
  rejectJobs: {
    result: null,
    error: null,
    requesting: false
  },
  updateStatus: {
    result: null,
    error: null,
    requesting: false
  },
  jobStatus: {
    result: null,
    error: null,
    requesting: false
  },
  report: {
    result: [],
    error: null,
    requesting: false
  },
  lookup: {
    result: [],
    error: null,
    requesting: false
  }
};

export const transactions = handleActions({
  QUICK_ADD_REQUEST: (state) => ({
    ...state,
    quickAdd: {
      ...state.quickAdd,
      requesting: true,
      error: null
    }
  }),

  QUICK_ADD_SUCCESS: (state, { payload }) => ({
    ...state,
    quickAdd: {
      ...state.quickAdd,
      result: payload.data,
      requesting: false,
      error: null
    }
  }),

  QUICK_ADD_FAIL: (state, { payload }) => ({
    ...state,
    quickAdd: {
      ...state.quickAdd,
      requesting: false,
      error: payload.error
    }
  }),

  EDIT_QUICK_ADD_REQUEST: (state) => ({
    ...state,
    editQuickAdd: {
      ...state.editQuickAdd,
      requesting: true,
      error: null
    }
  }),

  EDIT_QUICK_ADD_SUCCESS: (state, { payload }) => ({
    ...state,
    editQuickAdd: {
      ...state.editQuickAdd,
      result: payload.data,
      requesting: false,
      error: null
    }
  }),

  EDIT_QUICK_ADD_FAIL: (state, { payload }) => ({
    ...state,
    editQuickAdd: {
      ...state.editQuickAdd,
      requesting: false,
      error: payload.error
    }
  }),

  FETCH_TRANSACTIONS_REQUEST: (state) => ({
    ...state,
    transactions: {
      ...state.transactions,
      requesting: true,
      error: null
    }
  }),

  FETCH_TRANSACTIONS_SUCCESS: (state, { payload }) => ({
    ...state,
    transactions: {
      ...state.transactions,
      result: payload,
      requesting: false,
      error: null
    }
  }),

  FETCH_TRANSACTIONS_FAIL: (state, { payload }) => ({
    ...state,
    transactions: {
      ...state.transactions,
      requesting: false,
      error: payload.error
    }
  }),

  ADD_TRANSACTION_REQUEST: (state) => ({
    ...state,
    addTransaction: {
      ...state.addTransaction,
      requesting: true,
      error: null
    }
  }),

  ADD_TRANSACTION_SUCCESS: (state, { payload }) => ({
    ...state,
    addTransaction: {
      ...state.addTransaction,
      result: payload,
      requesting: false,
      error: null
    }
  }),

  ADD_TRANSACTION_FAIL: (state, { payload }) => ({
    ...state,
    addTransaction: {
      ...state.addTransaction,
      requesting: false,
      error: payload.error
    }
  }),

  /** EDIT TRANSACTION **/
  EDIT_TRANSACTION_REQUEST: (state) => ({
    ...state,
    editTransaction: {
      ...state.editTransaction,
      requesting: true,
      error: null
    }
  }),

  EDIT_TRANSACTION_SUCCESS: (state, { payload }) => ({
    ...state,
    editTransaction: {
      ...state.editTransaction,
      result: payload,
      requesting: false,
      error: null
    }
  }),

  EDIT_TRANSACTION_FAIL: (state, { payload }) => ({
    ...state,
    editTransaction: {
      ...state.editTransaction,
      requesting: false,
      error: payload.error
    }
  }),

  /** FETCH TRANSACTION INFO **/
  FETCH_TRANSACTION_INFO_REQUEST: (state) => ({
    ...state,
    transactionInfo: {
      ...state.transactionInfo,
      requesting: true,
      error: null
    }
  }),
  FETCH_TRANSACTION_INFO_SUCCESS: (state, { payload }) => ({
    ...state,
    transactionInfo: {
      ...state.transactionInfo,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_TRANSACTION_INFO_FAIL: (state, { payload }) => ({
    ...state,
    transactionInfo: {
      ...state.transactionInfo,
      requesting: false,
      error: payload.error
    }
  }),

  FETCH_STAFFS_REQUEST: (state) => ({
    ...state,
    staffs: {
      ...state.staffs,
      requesting: true,
      error: null
    }
  }),
  FETCH_STAFFS_SUCCESS: (state, { payload }) => ({
    ...state,
    staffs: {
      ...state.staffs,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_STAFFS_FAIL: (state, { payload }) => ({
    ...state,
    staffs: {
      ...state.staffs,
      requesting: false,
      error: payload.error
    }
  }),
  ASSIGN_STAFF_REQUEST: (state) => ({
    ...state,
    assignStaff: {
      ...state.assignStaff,
      requesting: true,
      error: null
    }
  }),
  ASSIGN_STAFF_SUCCESS: (state, { payload }) => ({
    ...state,
    assignStaff: {
      ...state.assignStaff,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  ASSIGN_STAFF_FAIL: (state, { payload }) => ({
    ...state,
    assignStaff: {
      ...state.assignStaff,
      requesting: false,
      error: payload.error
    }
  }),
  SET_TRANSACTION_IDS: (state, { payload }) => ({
    ...state,
    transactionIds: payload
  }),
  SET_STATUS_TRANSACTION_REQUEST: (state) => ({
    ...state,
    setStatusTransaction: {
      ...state.setStatusTransaction,
      requesting: true,
      error: null
    }
  }),
  SET_STATUS_TRANSACTION_SUCCESS: (state, { payload }) => ({
    ...state,
    setStatusTransaction: {
      ...state.setStatusTransaction,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  SET_STATUS_TRANSACTION_FAIL: (state, { payload }) => ({
    ...state,
    setStatusTransaction: {
      ...state.setStatusTransaction,
      requesting: false,
      error: payload.error
    }
  }),
  ASSIGN_JOB_REQUEST: (state) => ({
    ...state,
    assignJob: {
      ...state.assignJob,
      requesting: true,
      error: null
    }
  }),
  ASSIGN_JOB_SUCCESS: (state, { payload }) => ({
    ...state,
    assignJob: {
      ...state.assignJob,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  ASSIGN_JOB_FAIL: (state, { payload }) => ({
    ...state,
    assignJob: {
      ...state.assignJob,
      requesting: false,
      error: payload.error
    }
  }),
  FETCH_MANAGERS_REQUEST: (state) => ({
    ...state,
    managers: {
      ...state.managers,
      requesting: true,
      error: null
    }
  }),
  FETCH_MANAGERS_SUCCESS: (state, { payload }) => ({
    ...state,
    managers: {
      ...state.managers,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_MANAGERS_FAIL: (state, { payload }) => ({
    ...state,
    managers: {
      ...state.managers,
      requesting: false,
      error: payload.error
    }
  }),
  SET_STATUS_TRANSACTION_BY_ID: (state, { payload }) => ({
    ...state,
    transactions: {
      ...state.transactions,
      result: payload
    }
  }),
  /** INITIAL USER DATA **/
  SET_INITIAL_USER_DATA: () => initialState,

  SET_ACCOUNT_STATUS_REQUEST: (state) => ({
    ...state,
    accountStatus: {
      ...state.accountStatus,
      requesting: true,
      error: null
    }
  }),
  SET_ACCOUNT_STATUS_SUCCESS: (state, { payload }) => ({
    ...state,
    accountStatus: {
      ...state.accountStatus,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  SET_ACCOUNT_STATUS_FAIL: (state, { payload }) => ({
    ...state,
    accountStatus: {
      ...state.accountStatus,
      requesting: false,
      error: payload.error
    }
  }),

  SET_UPDATE_STATUS_REQUEST: (state) => ({
    ...state,
    updateStatus: {
      ...state.updateStatus,
      requesting: true,
      error: null
    }
  }),
  SET_UPDATE_STATUS_SUCCESS: (state, { payload }) => ({
    ...state,
    updateStatus: {
      ...state.updateStatus,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  SET_UPDATE_STATUS_FAIL: (state, { payload }) => ({
    ...state,
    updateStatus: {
      ...state.updateStatus,
      requesting: false,
      error: payload.error
    }
  }),

  REJECT_JOBS_REQUEST: (state) => ({
    ...state,
    rejectJobs: {
      ...state.rejectJobs,
      requesting: true,
      error: null
    }
  }),
  REJECT_JOBS_SUCCESS: (state, { payload }) => ({
    ...state,
    rejectJobs: {
      ...state.rejectJobs,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  REJECT_JOBS_FAIL: (state, { payload }) => ({
    ...state,
    rejectJobs: {
      ...state.rejectJobs,
      requesting: false,
      error: payload.error
    }
  }),

  PRINT_ENVELOPE_REQUEST: (state) => ({
    ...state,
    printEnvelope: {
      ...state.printEnvelope,
      requesting: true,
      error: null
    }
  }),
  PRINT_ENVELOPE_SUCCESS: (state, { payload }) => ({
    ...state,
    printEnvelope: {
      ...state.printEnvelope,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  PRINT_ENVELOPE_FAIL: (state, { payload }) => ({
    ...state,
    printEnvelope: {
      ...state.printEnvelope,
      requesting: false,
      error: payload.error
    }
  }),

  SET_JOB_STATUS_REQUEST: (state) => ({
    ...state,
    jobStatus: {
      ...state.jobStatus,
      requesting: true,
      error: null
    }
  }),
  SET_JOB_STATUS_SUCCESS: (state, { payload }) => ({
    ...state,
    jobStatus: {
      ...state.jobStatus,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  SET_JOB_STATUS_FAIL: (state, { payload }) => ({
    ...state,
    jobStatus: {
      ...state.jobStatus,
      requesting: false,
      error: payload.error
    }
  }),

  REPORT_BY_RANGE_REQUEST: (state) => ({
    ...state,
    report: {
      ...state.report,
      requesting: true,
      error: null
    }
  }),
  REPORT_BY_RANGE_SUCCESS: (state, { payload }) => ({
    ...state,
    report: {
      ...state.report,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  REPORT_BY_RANGE_FAIL: (state, { payload }) => ({
    ...state,
    report: {
      ...state.report,
      requesting: false,
      error: payload.error
    }
  }),

  PROPERTY_LOOKUP_REQUEST: (state) => ({
    ...state,
    lookup: {
      ...state.lookup,
      requesting: true,
      error: null
    }
  }),
  PROPERTY_LOOKUP_SUCCESS: (state, { payload }) => ({
    ...state,
    lookup: {
      ...state.lookup,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  PROPERTY_LOOKUP_FAIL: (state, { payload }) => ({
    ...state,
    lookup: {
      ...state.lookup,
      requesting: false,
      error: payload.error
    }
  })
}, initialState);

export default transactions;
