import http from '../../../services/http';

export default class EmailStore {
  static fetchEmails(search) {
    return http.get('/EmailStores', {
      params: {
        filter: {
          fields: ['email', 'name'],
          where: {
            or: [
              { email: { regexp: new RegExp(search, 'i').toString() } },
              { name: { regexp: new RegExp(search, 'i').toString() } }
            ]
          },
          limit: 5
        }
      }
    });
  }
}
