import { handleActions } from 'redux-actions';

const initialState = {
  companies: {
    requesting: false,
    result: null,
    error: null
  },
  createCompany: {
    requesting: false,
    result: null,
    error: null
  },
  companyExist: {
    requesting: false,
    result: null,
    error: null
  },
  allCompanies: {
    requesting: false,
    result: null,
    error: null
  },
  removeCompany: {
    requesting: false,
    result: null,
    error: null
  },
  editCompany: {
    requesting: false,
    result: null,
    error: null
  },
  deleteCompany: {
    requesting: false,
    result: null,
    error: null
  }
};

export const company = handleActions({
  CREATE_COMPANY_REQUEST: (state) => ({
    ...state,
    createCompany: {
      ...state.createCompany,
      requesting: true,
      error: null
    }
  }),
  CREATE_COMPANY_SUCCESS: (state, { payload }) => ({
    ...state,
    createCompany: {
      ...state.createCompany,
      requesting: false,
      result: payload.data
    }
  }),
  CREATE_COMPANY_FAIL: (state, { payload }) => ({
    ...state,
    createCompany: {
      ...state.createCompany,
      requesting: false,
      error: payload.error
    }
  }),
  FETCH_ALL_COMPANY_REQUEST: (state) => ({
    ...state,
    companies: {
      ...state.companies,
      requesting: true,
      error: null
    }
  }),
  FETCH_ALL_COMPANY_SUCCESS: (state, { payload }) => ({
    ...state,
    companies: {
      ...state.companies,
      requesting: false,
      result: payload.data
    }
  }),
  FETCH_ALL_COMPANY_FAIL: (state, { payload }) => ({
    ...state,
    companies: {
      ...state.companies,
      requesting: false,
      error: payload.error
    }
  }),
  CHECK_COMPANY_EXIST_REQUEST: (state) => ({
    ...state,
    companyExist: {
      ...state.companyExist,
      requesting: true,
      error: null
    }
  }),
  CHECK_COMPANY_EXIST_SUCCESS: (state, { payload }) => ({
    ...state,
    companyExist: {
      ...state.companyExist,
      requesting: false,
      result: payload.data
    }
  }),
  CHECK_COMPANY_EXIST_FAIL: (state, { payload }) => ({
    ...state,
    companyExist: {
      ...state.companyExist,
      requesting: false,
      error: payload.error
    }
  }),
  FETCH_COMPANIES_REQUEST: (state) => ({
    ...state,
    allCompanies: {
      ...state.allCompanies,
      requesting: true,
      error: null
    }
  }),
  FETCH_COMPANIES_SUCCESS: (state, { payload }) => ({
    ...state,
    allCompanies: {
      ...state.allCompanies,
      requesting: false,
      result: payload.data
    }
  }),
  FETCH_COMPANIES_FAIL: (state, { payload }) => ({
    ...state,
    allCompanies: {
      ...state.allCompanies,
      requesting: false,
      error: payload.error
    }
  }),
  REMOVE_COMPANY_REQUEST: (state) => ({
    ...state,
    removeCompany: {
      ...state.removeCompany,
      requesting: true,
      error: null
    }
  }),
  REMOVE_COMPANY_SUCCESS: (state, { payload }) => ({
    ...state,
    removeCompany: {
      ...state.removeCompany,
      requesting: false,
      result: payload.data
    }
  }),
  REMOVE_COMPANY_FAIL: (state, { payload }) => ({
    ...state,
    removeCompany: {
      ...state.removeCompany,
      requesting: false,
      error: payload.error
    }
  }),
  EDIT_COMPANY_REQUEST: (state) => ({
    ...state,
    editCompany: {
      ...state.editCompany,
      requesting: true,
      error: null
    }
  }),
  EDIT_COMPANY_SUCCESS: (state, { payload }) => ({
    ...state,
    editCompany: {
      ...state.editCompany,
      requesting: false,
      result: payload.data
    }
  }),
  EDIT_COMPANY_FAIL: (state, { payload }) => ({
    ...state,
    editCompany: {
      ...state.editCompany,
      requesting: false,
      error: payload.error
    }
  }),
  DELETE_COMPANY_REQUEST: (state) => ({
    ...state,
    deleteCompany: {
      ...state.deleteCompany,
      requesting: true,
      error: null
    }
  }),
  DELETE_COMPANY_SUCCESS: (state, { payload }) => ({
    ...state,
    deleteCompany: {
      ...state.deleteCompany,
      requesting: false,
      result: payload.data
    }
  }),
  DELETE_COMPANY_FAIL: (state, { payload }) => ({
    ...state,
    deleteCompany: {
      ...state.deleteCompany,
      requesting: false,
      error: payload.error
    }
  })
}, initialState);

export default company;
