import axios from 'axios';
import moment from 'moment';

import { encryptAES } from '../utils/encryption';
import AES from '../constants/encryptAES';
import { omit } from 'lodash';

/* eslint-disable dot-notation */
const httpClient = axios.create();
httpClient.defaults.baseURL = process.env.REACT_APP_API_URL;
httpClient.defaults.timeout = 120000;
httpClient.defaults.headers.common['Accept'] = 'application/json';
httpClient.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
httpClient.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
httpClient.defaults.headers.get['Content-Type'] = 'multipart/form-data';

httpClient.interceptors.request.use(
  config => {
    const data = JSON.stringify({
      time: moment()
        .valueOf()
    });
    const encrypted = encryptAES(data, AES.KEY, AES.IV);
    config.headers['x-verify'] = encrypted;
    return config;
  },
  error => Promise.reject(error)
);

httpClient.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(
      error.data
      || error.response
      || error.request
      || error.message
      || error
    );
  }
);

const http = {
  setAuthorizationHeader(accessToken) {
    httpClient.defaults.headers.Authorization = accessToken;
  },
  request(config = {}) {
    return httpClient.request(config);
  },
  get(url, config = {}) {
    return httpClient.get(url, {
      withCredentials: false,
      crossdomain: true,
      headers: {
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '*',
        ...config.headers ? config.headers : {}
      }, ...omit(config, ['headers'])
    });
  },
  post(url, data = {}, config = {}) {
    return httpClient.post(url, data, config);
  },
  put(url, data = {}, config = {}) {
    return httpClient.put(url, data, config);
  },
  patch(url, data = {}, config = {}) {
    return httpClient.patch(url, data, config);
  },
  delete(url, config = {}) {
    return httpClient.delete(url, config);
  }
};

export default http;
