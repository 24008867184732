import { handleActions } from 'redux-actions';

const initialState = {
  emails: {
    requesting: false,
    result: [],
    error: null
  }
};

export const emailStore = handleActions({
  FETCH_EMAIL_SUGGESTION_REQUEST: (state) => ({
    ...state,
    emails: {
      ...state.emails,
      requesting: true,
      error: null
    }
  }),
  FETCH_EMAIL_SUGGESTION_SUCCESS: (state, { payload }) => ({
    ...state,
    emails: {
      ...state.emails,
      requesting: false,
      result: payload.data
    }
  }),
  FETCH_EMAIL_SUGGESTION_FAIL: (state, { payload }) => ({
    ...state,
    emails: {
      ...state.emails,
      requesting: false,
      error: payload.error
    }
  })
}, initialState);

export default emailStore;
