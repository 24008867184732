import styled from 'styled-components';

const Wrapper = styled.div`
  .space-between-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .header-billing {
    font-weight: 500;
    color: #000000d9;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 0%);
    font-size: 14px;
  }
`;

const ModalFooterWrapper = styled.div`
  .footerBtn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center
  }

  .footerBtn > span {
    display: flex
  }
`;

export { ModalFooterWrapper };
export default Wrapper;
