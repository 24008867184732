import React from 'react';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import moment from 'moment';

import MainRoutes from './navigator';
import configureStore from './store/configureStore';

import AppLocale from './languageProvider';
import config, { getCurrentLanguage } from './containers/LanguageSwitcher/config';

import themes from './settings/themes';
import { themeConfig } from './settings';
import DashAppHolder from './dashAppStyle';

moment.locale('en');
const initialState = {};
const { store, history, persistor } = configureStore(initialState);

const currentAppLocale
  = AppLocale[getCurrentLanguage(config.defaultLanguage || 'english').locale];

const DashApp = () => (
  <ConfigProvider locale={currentAppLocale.antd}>
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <DashAppHolder>
          <PersistGate persistor={persistor}>
            <Provider store={store}>
              <MainRoutes history={history} />
            </Provider>
          </PersistGate>
        </DashAppHolder>
      </ThemeProvider>
    </IntlProvider>
  </ConfigProvider>
);

export default DashApp;
export { AppLocale };
