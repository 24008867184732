import React, { Component } from 'react';
import {
  AutoComplete,
  Form
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';

import Input, { Textarea } from '../../Isomorphic/uielements/input';

const { Option } = AutoComplete;

/* eslint-disable */
class FieldValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options
    };
  }

  onChange = (e) => {
    const { form, isUpperCase, type } = this.props;
    const { handleChange } = form;
    if (isUpperCase && e.target.value) {
      let value = e.target.value.toString()
        .toUpperCase();
      if (type === 'number' || type === 'currency') {
        value = value.replace(/[^\d.]/g, '')
          .replace(',', '.');
      }

      e.target.value = value;
    }
    handleChange(e);
  };

  onSearch = searchText => {
    if (!searchText) {
      return this.setState({ options: [] });
    }
    const regex = new RegExp(searchText, 'i');
    this.setState({
      options: !searchText ? [] : this.props.options.filter(item => regex.test(item))
    });
  };

  onSelect = (data) => {
    const { form, field, isUpperCase } = this.props;
    const { setFieldValue } = form;
    if (!data) {
      data = '';
    }
    data = data.toString();

    setFieldValue(field.name, isUpperCase ? data.toUpperCase() : data);
  };

  onChangeAutoCompete = (data) => {
    const { form, field, isUpperCase } = this.props;
    const { setFieldValue } = form;
    if (!data) {
      data = '';
    }
    data = data.toString();

    setFieldValue(field.name, isUpperCase ? data.toUpperCase() : data);
  };

  render() {
    const {
      type,
      id,
      label,
      field,
      placeholder,
      form,
      inputSize,
      containerClassName,
      textArea,
      formItemLayout,
      isDisableInput,
      onBlur,
      count,
      isAutocomplete,
      isUpperCase,
      inputProps,
      hasError
    } = this.props;

    let borderStyle = {};
    if (hasError) {
      borderStyle = { border: '1px solid red' };
    }

    const { errors } = form;
    const fieldError = get(errors, `${field.name}`);
    const validateStatus = fieldError ? 'error' : '';

    let value = (field.value || '').toString();
    if (!value) {
      value = '';
    }
    if (isUpperCase) {
      value = value.toUpperCase();
    }

    if (isAutocomplete) {
      const { options } = this.state;
      return (
        <Form.Item
          {...formItemLayout}
          id={id}
          label={label}
          className={containerClassName}
          validateStatus={validateStatus}
          help={fieldError}
        >
          <AutoComplete
            value={value}
            onSearch={this.onSearch}
            onChange={this.onChangeAutoCompete}
            onSelect={this.onSelect}
            placeholder={placeholder}
            style={{ ...borderStyle }}
          >
            {options && options.map((text) => (
              <Option key={text} value={text}>
                {text}
              </Option>
            ))}
          </AutoComplete>
        </Form.Item>
      );
    }

    if (textArea) {
      return (
        <Form.Item
          {...formItemLayout}
          id={id}
          label={label}
          className={containerClassName}
          validateStatus={validateStatus}
          help={fieldError}
        >
          <Textarea
            style={{ backgroundColor: isDisableInput ? '#fafafa' : 'white' }}
            name={field.name}
            value={value}
            type={type || 'text'}
            onChange={this.onChange}
            size={inputSize || 'default'}
            placeholder={placeholder || ''}
            disabled={isDisableInput}
          />
        </Form.Item>
      );
    }

    const inputPrefix = {};
    if (type === 'currency') {
      inputPrefix.prefix = '$';
      inputPrefix.suffix = 'USD';
    }

    if (type === 'date') {
      const z = moment()
        .format('Z');
      if (/^\d+$/.test(value)) {
        value = moment(parseInt(value)).utcOffset(z).format('YYYY-MM-DD');
      } else if (value.includes('Z')) {
        value = moment(value).utcOffset(z).format('YYYY-MM-DD');
      } else if (!value) {
        value = '';
      }
    }

    return (
      <Form.Item
        {...formItemLayout}
        id={id}
        label={label}
        className={containerClassName}
        validateStatus={validateStatus}
        help={fieldError}
      >
        <Input
          style={{ backgroundColor: isDisableInput ? '#fafafa' : 'white', ...borderStyle }}
          onBlur={onBlur}
          name={field.name}
          value={value}
          type={type === 'currency' ? 'text' : (type || 'text')}
          onChange={this.onChange}
          size={inputSize || 'default'}
          placeholder={placeholder || ''}
          disabled={isDisableInput}
          count={count}
          {...inputPrefix}
          {...inputProps}
        />
      </Form.Item>
    );
  }
}

export default FieldValidation;
