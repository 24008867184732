import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '/',
    exact: true,
    component: asyncComponent(() => import('../Pages/Dashboard'))
  },
  {
    path: '/dashboard',
    exact: true,
    component: asyncComponent(() => import('../Pages/Dashboard'))
  },
  {
    path: '/transactions',
    exact: true,
    component: asyncComponent(() => import('../Pages/Dashboard'))
  },
  {
    path: '/blankPage',
    exact: true,
    component: asyncComponent(() => import('../blankPage'))
  },
  {
    path: '/new-transaction',
    exact: true,
    component: asyncComponent(() => import('../Pages/NewTransactions'))
  },
  {
    path: '/quick-add',
    exact: true,
    component: asyncComponent(() => import('../Pages/QuickAdd'))
  },
  {
    path: '/quick-add/:transactionId',
    exact: true,
    component: asyncComponent(() => import('../Pages/EditQuickAdd'))
  },
  {
    path: '/transactions/:transactionId',
    exact: true,
    component: asyncComponent(() => import('../Pages/TransactionInfo'))
  },
  {
    path: '/transactions/:transactionId/property/:propertyId/documents',
    exact: true,
    component: asyncComponent(() => import('../Pages/DocumentManagement'))
  },
  {
    path: '/transactions/:transactionId/documents/:id',
    exact: true,
    component: asyncComponent(() => import('../Pages/DocumentIndex'))
  },
  {
    path: '/transactions/:transactionId/documenteditor',
    exact: true,
    component: asyncComponent(() => import('../Pages/Documenteditor'))
  },
  {
    path: '/transactions/:transactionId/documents/:id/view',
    exact: true,
    component: asyncComponent(() => import('../Pages/DocumentView'))
  },
  {
    path: '/mailbox',
    exact: true,
    component: asyncComponent(() => import('../Pages/MailBox'))
  },
  {
    path: '/profile',
    exact: true,
    component: asyncComponent(() => import('../Pages/Profile'))
  },
  {
    path: '/manager-user',
    exact: true,
    component: asyncComponent(() => import('../Pages/ManagerUser'))
  },
  {
    path: '/new-user',
    exact: true,
    component: asyncComponent(() => import('../Pages/CreateUser'))
  },
  {
    path: '/companies',
    exact: true,
    component: asyncComponent(() => import('../Pages/CompaniesManagement'))
  },
  {
    path: '/new-company',
    exact: true,
    component: asyncComponent(() => import('../Pages/NewCompany'))
  },
  {
    path: '/companies/:companyId',
    exact: true,
    component: asyncComponent(() => import('../Pages/EditCompany'))
  },
  {
    path: '/titlebell-orders',
    exact: true,
    component: asyncComponent(() => import('../Pages/TitlebellOrders'))
  },
  {
    path: '/vouchers',
    exact: true,
    component: asyncComponent(() => import('../Pages/Vouchers'))
  },
  {
    path: '/jobs',
    exact: true,
    component: asyncComponent(() => import('../Pages/Jobs'))
  },
  {
    path: '/jobs/:jobId',
    component: asyncComponent(() => import('../Pages/CustomerServices'))
  },
  {
    path: '/property-lookup',
    component: asyncComponent(() => import('../Pages/PropertyLookup'))
  },
  {
    path: '/billing-history',
    component: asyncComponent(() => import('../Pages/BillingHistories'))
  },
  {
    path: '/billing-charge',
    component: asyncComponent(() => import('../Pages/BillingCharge'))
  }
];

export default routes;
