/* eslint-disable */
import React, { Component } from 'react';
import { WithOutContext as ReactTags } from 'react-tag-input';
import {
  get,
  isEmpty
} from 'lodash';
import { message } from 'antd';
import PropTypes from 'prop-types';

import { validateEmail } from '../../utils/common';
import AutoComplete from './styles';

class ComposeAutoComplete extends Component {
  static propTypes = {
    height: PropTypes.number,
    mailBoxes: PropTypes.array,
    lastEmail: PropTypes.string
  };

  constructor(props) {
    super(props);

    const {
      tags,
      lastEmail,
      handleTab
    } = props;

    const mailBoxes = get(props, 'mailBoxes', []);
    let newTag = this.initTags(tags);

    if (lastEmail && mailBoxes) {
      newTag = this.initTags(lastEmail);
    }

    handleTab(newTag);

    this.state = {
      tags: newTag,
      suggestions: []
    };
  }

  initTags(value) {
    if (!value || isEmpty(value)) {
      return [];
    }
    const {
      email, secondEmail
    } = this.props;
    if (value.indexOf(',') === -1) {
      return [{
        id: '01',
        text: value
      }];
    }
    const arr = value.split(',');
    const data = arr.map((ele, index) => {
      return {
        id: `${index}`,
        text: ele
      };
    });
    return data.filter(ele => ![email, secondEmail].includes(ele.text));
  }

  // hanlde again suggestions data correct
  initSuggestions = () => {
    const { mailSuggestion } = this.props;
    const formatSuggestions = mailSuggestion.map((e, index) => {
      return {
        id: `${(index + 1).toString()}`,
        text: e.email
      };
    });

    return formatSuggestions;
  };

  handleDelete = (i) => {
    const { tags } = this.state;

    this.setState({
      tags: tags.filter((tag, index) => index !== i)
    });
  };

  handleOnPaste = (tag) => {
    const splitTag = tag.text.split(',');
    const resutlPasted = splitTag.map((ele, index) => {
      return {
        id: index.toString(),
        text: ele
      };
    });
    return resutlPasted;
  };

  handleAddition = tag => {
    const { tags } = this.state;
    const { handleTab } = this.props;

    if (!validateEmail(tag.text)) {
      message.error('Invalid email address, please enter email correct !');
    } else {
      this.setState({ tags: [...tags, ...this.handleOnPaste(tag)] }, () => {
        const { tags } = this.state;
        handleTab(tags);
      });
    }
  };

  handleDrag(tag, currPos, newPos) {
    const { tags } = this.state;
    let formatSuggestions = tags.map((e, index) => {
      return {
        id: `${index + 1}`,
        text: e
      };
    });
    formatSuggestions.splice(currPos, 1);
    formatSuggestions.splice(newPos, 0, tag);
    this.setState({ tags: formatSuggestions });
  }

  handleInputBlur(tag) {
    const { tags } = this.state;
    const blurTag = {
      id: tag,
      text: tag
    };

    if (!validateEmail(tag)) {
      message.error('Invalid email address, please enter email correct !');
    } else {
      this.setState({ tags: [...tags, blurTag] });
    }
  }

  render() {
    const { tags } = this.state;
    const {
      onChange,
      placeholder
    } = this.props;

    return (
      <AutoComplete>
        <ReactTags
          tags={tags}
          suggestions={this.initSuggestions()}
          handleInputChange={e => onChange(e)}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          handleInputBlur={tag => tag && this.handleInputBlur(tag)} // if tag argrument is null this func isn't called
          placeholder={placeholder}
          autofocus={this.props.autofocus || false}
        />
      </AutoComplete>
    );
  }
}

export default ComposeAutoComplete;
