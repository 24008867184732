import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, find, isEmpty } from 'lodash';

import { tags, tagColor } from './mailTags.js';
import { timeDifference } from '../../../helpers/utility';
import { rtl } from '../../../settings/withDirection';
import { readNotifications, fetchUnreadNotifications } from '../../../store/modules/User/action';

import MailListWrapper from './mailList.style';

class MailList extends Component {
  constructor(props) {
    super(props);

    const mailbox = get(props.notifications, 'result.mailbox', []);

    this.state = {
      index: '',
      hasNotification: isEmpty(mailbox) ? [] : mailbox.map(item => item.transactionId)
    };
  }

  onClick = (mail) => () => {
    const {
      selectMail,
      notifications,
      readNotifications
    } = this.props;


    const mailbox = get(notifications, 'result.mailbox', []);
    const transaction = find(mailbox, item => item.transactionId === mail.id);

    selectMail(mail.id);

    if (transaction) {
      readNotifications(mail.id).then(() => {
        const { fetchUnreadNotifications, userLogin } = this.props;
        const userId = get(userLogin, 'result.id', '');
        fetchUnreadNotifications(userId)
          .then(() => {
            const mailbox = get(this.props.notifications, 'result.mailbox', []);
            this.setState({ hasNotification: isEmpty(mailbox) ? [] : mailbox.map(item => item.transactionId) });
          });
      });
    }
  };

  renderSingleMail = (mail, key) => {
    const { hasNotification } = this.state;
    const { selectedMail } = this.props;

    const newestMail = get(mail, 'messengers.0', {});

    const isSelected = selectedMail === mail.id;
    const activeClass = isSelected ? 'activeMail' : '';
    const unreadClass = !mail.read ? 'unreadMail' : '';
    const propertyName = get(mail, 'properties.0.address', '');

    const tagOption = mail.tags
      ? tagColor[tags.findIndex(tags => tags === mail.tags)]
      : 'transparent';

    const unRead = hasNotification.indexOf(mail.id) !== -1;

    return (
      <div
        key={`list${key}`}
        onClick={this.onClick(mail)}
        className={`${activeClass} ${unreadClass} isoMailList`}
      >
        <span
          className="isoLabelIndicator"
          style={
            rtl === 'rtl'
              ? ({ borderRightColor: tagOption })
              : ({ borderTopColor: tagOption })
          }
        />

        <div className="isoMailInfo">
          <div className="infoHead">
            <p className="isoRecipents" style={{ fontWeight: unRead ? 500 : 300 }}>{propertyName}</p>
            <span className="isoReceiveDate" style={{ fontWeight: unRead ? 500 : 300 }}>{timeDifference(newestMail.updatedAt)}</span>
          </div>
          <p className="isoSubject" style={{ fontWeight: unRead ? 500 : 300 }}>{newestMail.subject}</p>
        </div>
      </div>
    );
  };

  render() {
    const { mails } = this.props;

    return (
      <MailListWrapper className="isoMailListWrapper">
        {mails.map((mail, index) => this.renderSingleMail(mail, index))}
      </MailListWrapper>
    );
  }
}

export default connect(
  state => ({
    userLogin: state.user.login,
    notifications: state.user.notifications,
    readNotifications: state.user.readNotifications
  }),
  dispatch => bindActionCreators({
    readNotifications,
    fetchUnreadNotifications
  }, dispatch)
)(MailList);
