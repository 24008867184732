/* eslint-disable */
import React, { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { ImageDrop } from 'quill-image-drop-module';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import QuillEditor from './styles/editor.style';

window.Quill = Quill;
Quill.register('modules/imageDrop', ImageDrop);

const BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
  'alt',
  'height',
  'width',
  'style'
];

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);

export default class Editor extends Component {
  constructor(props) {
    super(props);

    const ImageResize = require('quill-image-resize-module').default;
    Quill.register('modules/ImageResize', ImageResize);

    this.state = {};
    this.quillModules = {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'blockquote'],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'align': [] }],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' }
          ],
          ['image']
        ]
      },
      imageDrop: true,
      imageResize: {
        displaySize: true
      }
    };
  }

  render() {
    const {
      editorState,
      onEditorStateChange,
      ...rest
    } = this.props;

    const options = {
      theme: 'snow',
      formats: Editor.formats,
      placeholder: 'Write Something',
      value: editorState || '',
      onChange: onEditorStateChange,
      modules: this.quillModules
    };

    return (
      <QuillEditor>
        <ReactQuill {...options} {...rest} />
      </QuillEditor>
    );
  }
}
