/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Scrollbars from '../../../../components/Isomorphic/utility/customScrollBar.js';
import { InputSearch } from '../../../../components/Isomorphic/uielements/input.js';
import MailList from '../../../../components/Isomorphic/mail/maiilList.js';

import SingleMail from '../../../../components/Isomorphic/mail/singleMail.js';
import ComposeBtn from '../../../../components/Isomorphic/mail/mailComposeBtn.js';
import ComposeMail from '../../../../components/Isomorphic/mail/composeMail.js';
import PaginationControl from '../../../../components/Isomorphic/mail/mailPagination.js';
import IntlMessages from '../../../../components/Isomorphic/utility/intlMessages.js';

import MailBox from '../styles';

class DesktopView extends Component {
  static propTypes = {
    height: PropTypes.number,
    allMails: PropTypes.array,
    filterAttr: PropTypes.array,
    filterMails: PropTypes.array,
    searchString: PropTypes.string,
    selectedMail: PropTypes.string,
    selectMail: PropTypes.func,
    changeComposeMail: PropTypes.func,
    composeMail: PropTypes.any,
    replyMail: PropTypes.any,
    filterAction: PropTypes.any,
    changeReplyMail: PropTypes.any,
    filterBucket: PropTypes.func,
    onSearch: PropTypes.func,
    onChangeSearch: PropTypes.func,
    transactionIds: PropTypes.array,
    sendMail: PropTypes.func
  };

  constructor(props) {
    super(props);
    const { searchString } = this.props;
    this.state = {
      search: searchString,
      composeMail: false
    };
  }

  handleChangeComposeMail = value => {
    this.setState({ composeMail: value });
  };

  handleSuccessSendMail = value => {
    this.setState({ composeMail: value });
  };

  render() {
    const {
      allMails,
      selectedMail,
      selectMail,
      onSearch,
      onChangeSearch,
      sendMail,
      isEnableCompose,
      secondEmail,
      email,
      isRequesting,
      withOutContext
    } = this.props;

    const { composeMail } = this.state;

    const index = allMails && allMails.findIndex(mail => mail.id === selectedMail);

    return (
      <MailBox className="isoLayoutContentWrapper">
        {
          composeMail ? (
            <ComposeMail
              allMails={allMails}
              sendMail={sendMail}
              changeComposeMail={this.handleChangeComposeMail}
              handleSuccessSendMail={this.handleSuccessSendMail}
              selectedMail={selectedMail}
              isRequesting={isRequesting}
              withOutContext={withOutContext}
            />
          ) : (
            <div className="isoMiddleWrapper">
              <ComposeBtn changeComposeMail={this.handleChangeComposeMail} isEnableCompose={isEnableCompose} />
              <div className="isoBucketLabel">
                <h3>{allMails.length} of {allMails.length}</h3>
                <PaginationControl />
              </div>
              <div className="isoSearchMailWrapper">
                <InputSearch
                  placeholder="Search Email"
                  className="isoSearchEmail"
                  onChange={event => onChangeSearch(event.target.value)}
                  onSearch={value => onSearch(value)}
                />
              </div>
              <Scrollbars>
                <MailList
                  mails={allMails}
                  selectMail={selectMail}
                  selectedMail={selectedMail}
                />
              </Scrollbars>
            </div>
          )
        }
        {
          composeMail ? (
            ''
          ) : (
            <div className="isoSingleMailWrapper">
              <Scrollbars>
                {
                  allMails && !allMails.length && (
                    <p className="isoNoMailMsg">
                      <IntlMessages id="email.noMessage" />
                    </p>
                  )
                }
                {
                  index !== -1 && (
                    <SingleMail
                      allMails={allMails}
                      index={index}
                      sendMail={sendMail}
                      selectedMail={selectedMail}
                      secondEmail={secondEmail}
                      email={email}
                      isRequesting={isRequesting}
                      withOutContext={withOutContext}
                    />
                  )
                }
              </Scrollbars>
            </div>
          )
        }
      </MailBox>
    );
  }
}

export default DesktopView;
