import React from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import App from './containers/App';
import routes from './containers/App/routes';
import asyncComponent from './helpers/AsyncFunc';

const PublicRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isAuthenticated
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/dashboard',
            state: { from: props.location }
          }}
        />
    }
  />
);

function MainRoutes() {
  const isAuthenticated = useSelector(state => state?.user?.login?.result?.accessToken);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          {isAuthenticated ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
        </Route>

        <PublicRoute
          exact
          path={'/transactions/:transactionId/documents-public'}
          isAuthenticated={isAuthenticated}
          component={asyncComponent(() => import('./containers/Pages/DocumentManagement'))}
        />

        <PublicRoute
          exact
          path={'/login'}
          isAuthenticated={isAuthenticated}
          component={asyncComponent(() => import('./containers/Pages/Login'))}
        />

        <PublicRoute
          exact
          path={'/sign-up'}
          isAuthenticated={isAuthenticated}
          component={asyncComponent(() => import('./containers/Pages/SignUp'))}
        />

        <PublicRoute
          exact
          path={'/login'}
          isAuthenticated={isAuthenticated}
          component={asyncComponent(() => import('./containers/Pages/Login'))}
        />

        {routes.map((route, i) =>
          <App key={i} isAuthenticated={isAuthenticated} {...route} />)}

      </Switch>
    </Router>
  );
}

export default MainRoutes;
