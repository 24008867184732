import React from 'react';
import { Spin } from 'antd';

export default function LoadingFullContainer() {
  return (
    <div
      style={{
        textAlign: 'center',
        marginBottom: '20px',
        padding: '30px 50px',
        margin: '20px 0',
      }}
    >
      <Spin />
    </div>
  );
}
