import {
  createAction,
  createActions
} from 'redux-actions';
import {
  get,
  groupBy,
  isEmpty,
  uniqBy,
  forEach
} from 'lodash';
import jwtDecode from 'jwt-decode';

import Api from './api';
import http from '../../../services/http';

/** LOGIN **/
const { loginRequest, loginSuccess, loginFail } = createActions({
  LOGIN_REQUEST: () => { },
  LOGIN_SUCCESS: data => ({ data }),
  LOGIN_FAIL: error => ({ error })
});

export const login = credentials => (dispatch) => {
  dispatch(loginRequest());

  return Api.login(credentials)
    .then(({ data }) => {
      const token = get(data, 'accessToken', '');
      const decoded = jwtDecode(token);
      const role = get(decoded, 'roles.0', '');
      data.role = role;

      http.setAuthorizationHeader(token);
      dispatch(loginSuccess(data));
      return data;
    })
    .catch((error) => {
      dispatch(loginFail(error));
      return { error };
    });
};

/** SIGN UP **/
const { signUpRequest, signUpSuccess, signUpFail } = createActions({
  SIGN_UP_REQUEST: () => { },
  SIGN_UP_SUCCESS: data => ({ data }),
  SIGN_UP_FAIL: error => ({ error })
});

export const signUp = credentials => (dispatch) => {
  dispatch(signUpRequest());

  return Api.signUp(credentials)
    .then(({ data }) => {
      dispatch(signUpSuccess(data));
    })
    .catch((error) => {
      dispatch(signUpFail(error));
    });
};

/* clear data after user logout */
const setInitialUserData = createAction('SET_INITIAL_USER_DATA');

export const clearUserData = () => (dispatch) => {
  http.setAuthorizationHeader('');
  dispatch(setInitialUserData());
};


/** FETCH USERS DOCUMENTS **/
const { fetchUserDocumentsRequest, fetchUserDocumentsSuccess, fetchUserDocumentsFail } = createActions({
  FETCH_USER_DOCUMENTS_REQUEST: () => { },
  FETCH_USER_DOCUMENTS_SUCCESS: data => ({ data }),
  FETCH_USER_DOCUMENTS_FAIL: error => ({ error })
});

export const fetchUserDocuments = (userId) => (dispatch) => {
  dispatch(fetchUserDocumentsRequest());

  return Api.fetchUserDocuments(userId)
    .then(({ data }) => {
      dispatch(fetchUserDocumentsSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchUserDocumentsFail(error));
    });
};

const { sendMailRequest, sendMailSuccess, sendMailFail } = createActions({
  SEND_MAIL_REQUEST: () => { },
  SEND_MAIL_SUCCESS: data => ({ data }),
  SEND_MAIL_FAIL: error => ({ error })
});

export const sendMail = (userId, body) => (dispatch) => {
  dispatch(sendMailRequest());

  return Api.sendMail(userId, body)
    .then(({ data }) => {
      dispatch(sendMailSuccess(data));
    })
    .catch((error) => {
      dispatch(sendMailFail(error));
    });
};

const { fetchMailByTransactionRequest, fetchMailByTransactionSuccess, fetchMailByTransactionFail } = createActions({
  FETCH_MAIL_BY_TRANSACTION_REQUEST: () => { },
  FETCH_MAIL_BY_TRANSACTION_SUCCESS: data => ({ data }),
  FETCH_MAIL_BY_TRANSACTION_FAIL: error => ({ error })
});

export const fetchMailByTransaction = userId => (dispatch) => {
  dispatch(fetchMailByTransactionRequest());

  return Api.fetchMailByTransaction(userId)
    .then(({ data }) => {
      dispatch(fetchMailByTransactionSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchMailByTransactionFail(error));
    });
};

/** FETCH USERS INFORMATION **/
const { fetchUserInformationRequest, fetchUserInformationSuccess, fetchUserInformationFail } = createActions({
  FETCH_USER_INFORMATION_REQUEST: () => { },
  FETCH_USER_INFORMATION_SUCCESS: data => ({ data }),
  FETCH_USER_INFORMATION_FAIL: error => ({ error })
});

export const fetchUserInformation = (userId) => (dispatch) => {
  dispatch(fetchUserInformationRequest());

  return Api.fetchUserInformation(userId)
    .then(({ data }) => {
      dispatch(fetchUserInformationSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchUserInformationFail(error));
    });
};

export const clearUserInformation = () => dispatch => {
  dispatch(fetchUserInformationSuccess(null));
};

/** UPDATE USERS INFORMATION **/
const { updateUserInformationRequest, updateUserInformationSuccess, updateUserInformationFail } = createActions({
  UPDATE_USER_INFORMATION_REQUEST: () => { },
  UPDATE_USER_INFORMATION_SUCCESS: data => ({ data }),
  UPDATE_USER_INFORMATION_FAIL: error => ({ error })
});

export const updateUserInformation = (userId, body) => (dispatch) => {
  dispatch(updateUserInformationRequest());

  return Api.updateUserInformation(userId, body)
    .then(({ data }) => {
      dispatch(updateUserInformationSuccess(data));
    })
    .catch((error) => {
      dispatch(updateUserInformationFail(error));
    });
};

/** UPDATE USERS PASSWORD **/
const { updateUserPasswordRequest, updateUserPasswordSuccess, updateUserPasswordFail } = createActions({
  UPDATE_USER_PASSWORD_REQUEST: () => { },
  UPDATE_USER_PASSWORD_SUCCESS: data => ({ data }),
  UPDATE_USER_PASSWORD_FAIL: error => ({ error })
});

export const updateUserPassword = (body) => (dispatch) => {
  dispatch(updateUserPasswordRequest());

  return Api.updateUserPassword(body)
    .then(({ data }) => {
      dispatch(updateUserPasswordSuccess(data));
    })
    .catch((error) => {
      dispatch(updateUserPasswordFail(error));
    });
};

/** UPDATE USERS PASSWORD **/
const { fetchAllUserRequest, fetchAllUserSuccess, fetchAllUserFail } = createActions({
  FETCH_ALL_USER_REQUEST: () => { },
  FETCH_ALL_USER_SUCCESS: data => ({ data }),
  FETCH_ALL_USER_FAIL: error => ({ error })
});

export const fetchAllUser = (search, page, limit, spec) => (dispatch) => {
  dispatch(fetchAllUserRequest());

  return Api.fetchAllUser(search, page, limit, spec)
    .then(({ data }) => {
      dispatch(fetchAllUserSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchAllUserFail(error));
    });
};


/** SET ROLE FOR USER **/
const { setRoleForUserRequest, setRoleForUserSuccess, setRoleForUserFail } = createActions({
  SET_ROLE_FOR_USER_REQUEST: () => { },
  SET_ROLE_FOR_USER_SUCCESS: data => ({ data }),
  SET_ROLE_FOR_USER_FAIL: error => ({ error })
});

export const setRoleForUser = (body) => (dispatch) => {
  dispatch(setRoleForUserRequest());

  return Api.setRoleForUser(body)
    .then(({ data }) => {
      dispatch(setRoleForUserSuccess(data));
    })
    .catch((error) => {
      dispatch(setRoleForUserFail(error));
    });
};


/**  ADMIN CHANGE USER PASSWORD **/
const { adminChangeUserPasswordRequest, adminChangeUserPasswordSuccess, adminChangeUserPasswordFail } = createActions({
  ADMIN_CHANGE_USER_PASSWORD_REQUEST: () => { },
  ADMIN_CHANGE_USER_PASSWORD_SUCCESS: data => ({ data }),
  ADMIN_CHANGE_USER_PASSWORD_FAIL: error => ({ error })
});

export const adminChangeUserPassword = (body) => (dispatch) => {
  dispatch(adminChangeUserPasswordRequest());

  return Api.adminChangeUserPassword(body)
    .then(({ data }) => {
      dispatch(adminChangeUserPasswordSuccess(data));
    })
    .catch((error) => {
      dispatch(adminChangeUserPasswordFail(error));
    });
};

/**  FETCH UNREAD NOTIFICATIONS **/
const { fetchUnreadNotificationsRequest, fetchUnreadNotificationsSuccess, fetchUnreadNotificationsFail } = createActions({
  FETCH_UNREAD_NOTIFICATIONS_REQUEST: () => { },
  FETCH_UNREAD_NOTIFICATIONS_SUCCESS: data => ({ data }),
  FETCH_UNREAD_NOTIFICATIONS_FAIL: error => ({ error })
});

export const fetchUnreadNotifications = (userId) => (dispatch) => {
  dispatch(fetchUnreadNotificationsRequest());

  return Api.fetchUnreadNotifications(userId)
    .then(({ data }) => {
      // Handle data
      const group = groupBy(data, 'type');
      if (isEmpty(group)) {
        dispatch(fetchUnreadNotificationsSuccess({}));
      } else {
        forEach(group, (value, key) => {
          if (key === 'mailbox') {
            group[key] = uniqBy(group[key], 'transactionId');
          }
        });
        dispatch(fetchUnreadNotificationsSuccess(group));
      }
    })
    .catch((error) => {
      dispatch(fetchUnreadNotificationsFail(error));
    });
};

/**  READ NOTIFICATIONS **/
const { readNotificationsRequest, readNotificationsSuccess, readNotificationsFail } = createActions({
  READ_NOTIFICATIONS_REQUEST: () => { },
  READ_NOTIFICATIONS_SUCCESS: data => ({ data }),
  READ_NOTIFICATIONS_FAIL: error => ({ error })
});

export const readNotifications = (transactionId) => (dispatch) => {
  dispatch(readNotificationsRequest());

  return Api.readNotifications(transactionId)
    .then(({ data }) => {
      dispatch(readNotificationsSuccess(data));
    })
    .catch((error) => {
      dispatch(readNotificationsFail(error));
    });
};

/**  ADD COMPANY **/
const { addCompanyRequest, addCompanySuccess, addCompanyFail } = createActions({
  ADD_COMPANY_REQUEST: () => { },
  ADD_COMPANY_SUCCESS: data => ({ data }),
  ADD_COMPANY_FAIL: error => ({ error })
});

export const addCompany = (userId, companyId, staffOfUserId, role) => (dispatch) => {
  dispatch(addCompanyRequest());

  return Api.addCompany(userId, companyId, staffOfUserId, role)
    .then(({ data }) => {
      dispatch(addCompanySuccess(data));
    })
    .catch((error) => {
      dispatch(addCompanyFail(error));
    });
};

const { fetchUsersRequest, fetchUsersSuccess, fetchUsersFail } = createActions({
  FETCH_USERS_REQUEST: () => { },
  FETCH_USERS_SUCCESS: data => ({ data }),
  FETCH_USERS_FAIL: error => ({ error })
});

export const fetchUsers = params => (dispatch) => {
  dispatch(fetchUsersRequest());

  return Api.fetchUsers(params)
    .then(({ data }) => {
      dispatch(fetchUsersSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchUsersFail(error));
    });
};

/** BANNED USER **/
const { bannedUserRequest, bannedUserSuccess, bannedUserFail } = createActions({
  BANNED_USER_REQUEST: () => { },
  BANNED_USER_SUCCESS: data => ({ data }),
  BANNED_USER_FAIL: error => ({ error })
});

export const bannedUser = (body) => (dispatch) => {
  dispatch(bannedUserRequest());

  return Api.bannedUser(body)
    .then(({ data }) => {
      dispatch(bannedUserSuccess(data));
    })
    .catch((error) => {
      dispatch(bannedUserFail(error));
    });
};


/** UNBANNED USER **/
const { unbannedUserRequest, unbannedUserSuccess, unbannedUserFail } = createActions({
  UNBANNED_USER_REQUEST: () => { },
  UNBANNED_USER_SUCCESS: data => ({ data }),
  UNBANNED_USER_FAIL: error => ({ error })
});

export const unbannedUser = (body) => (dispatch) => {
  dispatch(unbannedUserRequest());

  return Api.unbannedUser(body)
    .then(({ data }) => {
      dispatch(unbannedUserSuccess(data));
    })
    .catch((error) => {
      dispatch(unbannedUserFail(error));
    });
};
