import { handleActions } from 'redux-actions';

import { getView } from '../../../utils/common';
import { getDefaultPath } from '../../../helpers/urlSync';
const preKeys = getDefaultPath();

const initialState = {
  collapsed: true,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys
};

export const common = handleActions({
  CLOSE_ALL: (state) => ({
    ...state,
    current: [],
    openKeys: []
  }),
  CHANGE_CURRENT: (state, { payload }) => ({
    ...state,
    current: payload
  }),
  CHANGE_OPEN_KEYS: (state, { payload }) => ({
    ...state,
    openKeys: payload
  }),
  TOGGLE_COLLAPSED: (state) => ({
    ...state,
    collapsed: !state.collapsed
  }),
  TOGGLE_OPEN_DRAWER: (state) => ({
    ...state,
    openDrawer: !state.openDrawer
  }),
  HANDLE_TOGGLE_ALL: (state, { payload }) => {
    if (state.view !== payload.view || payload.height !== state.height) {
      const height = payload.height ? payload.height : state.height;
      return {
        ...state,
        collapsed: payload.collapsed,
        view: payload.view,
        height
      };
    }
    return state;
  }
}, initialState);

export default common;
