import React, { Component } from 'react';
import {
  Checkbox,
  Icon,
  Modal,
  Row
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  find,
  get,
  concat,
  isEmpty
} from 'lodash';

import {
  fetchDocumentsOfProperty,
  getDocumentItems
} from '../../../../store/modules/Document/action';

class UploadDocument extends Component {
  static propTypes = {
    userInfo: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedDocument: [],
      checkAll: false,
      indeterminate: false,
      documents: []
    };
  }

  componentDidMount() {
    const { transactionId, fetchDocumentsOfProperty } = this.props;

    fetchDocumentsOfProperty(transactionId)
      .then(() => {
        const { propertyDocumentsState } = this.props;
        const categories = get(propertyDocumentsState, 'result.documents', []);
        let documents = [];
        categories.forEach((category) => {
          documents = concat(documents, get(category, 'documents', []));
        });
        this.setState({ documents });
      });
  }

  handleGetDocumentItems = (item) => () => {
    const { selectedDocument, documents } = this.state;
    let newSelectDocument = [];
    if (!get(find(selectedDocument, { id: item.id }), 'id', '')) {
      newSelectDocument = [...this.state.selectedDocument, item];
    } else {
      newSelectDocument = this.state.selectedDocument.filter(ele => ele.id !== item.id);
    }

    this.setState({
      selectedDocument: newSelectDocument,
      indeterminate: newSelectDocument.length > 0 && newSelectDocument.length < documents.length,
      checkAll: newSelectDocument.length === documents.length
    });
  };

  renderDocumentItems = () => {
    const { documents, selectedDocument } = this.state;

    const items = documents.map((ele, index) => {
      return (
        <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <div style={{ width: '78%', display: 'flex', flexDirection: 'row' }}>
            <Icon style={{ marginTop: 2 }} type="file-pdf" />
            <p style={{ marginLeft: 10 }}>{ele.fileName}</p>
          </div>
          <div style={{ width: '20%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Checkbox checked={!isEmpty(find(selectedDocument, { id: ele.id }))} onChange={this.handleGetDocumentItems(ele)}>Select</Checkbox>
          </div>
        </div>
      );
    });

    return items;
  };

  onCheckAllChange = e => {
    this.setState({
      selectedDocument: e.target.checked ? this.state.documents : [],
      indeterminate: false,
      checkAll: e.target.checked
    });
  };

  render() {
    const { selectedDocument } = this.state;
    const {
      visible,
      handleCancel,
      handleSubmit
    } = this.props;

    return (
      <Modal
        title="Documents of transaction"
        width="50%"
        visible={visible}
        onCancel={handleCancel}
        onOk={handleSubmit(selectedDocument)}
      >
        <Row style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
          <Checkbox
            indeterminate={this.state.indeterminate}
            onChange={this.onCheckAllChange}
            checked={this.state.checkAll}
          >
            Check all
          </Checkbox>
        </Row>
        {this.renderDocumentItems()}
      </Modal>
    );
  }
}

export default connect(
  state => ({
    propertyDocumentsState: state.document.propertyDocuments
  }),
  dispatch => bindActionCreators({
    getDocumentItems,
    fetchDocumentsOfProperty
  }, dispatch)
)(UploadDocument);
