import localForage from 'localforage';
import { persistReducer } from 'redux-persist';

import user from './modules/User/reducer';
import common from './modules/Common/reducer';
import document from './modules/Document/reducer';
import transactions from './modules/Transaction/reducer';
import emailStore from './modules/EmailStore/reducer';
import company from './modules/Company/reducer';
import order from './modules/Order/reducer';
import voucher from './modules/Voucher/reducer';
import job from './modules/Job/reducer';
import locality from './modules/Locality/reducer';
import billing from './modules/Bliing/reducer';

const userPersistConfig = {
  key: 'user',
  storage: localForage,
  whitelist: ['login']
};

export default {
  user: persistReducer(userPersistConfig, user),
  common,
  document,
  transactions,
  emailStore,
  company,
  order,
  voucher,
  job,
  locality,
  billing
};
