import { handleActions } from 'redux-actions';

const initialState = {
  vouchers: {
    requesting: false,
    result: [],
    error: null
  },
  edit: {
    requesting: false,
    result: null,
    error: null
  },
  generate: {
    requesting: false,
    result: [],
    error: null
  }
};

export const voucher = handleActions({
  FETCH_VOUCHERS_REQUEST: (state) => ({
    ...state,
    vouchers: {
      ...state.vouchers,
      requesting: true,
      error: null
    }
  }),
  FETCH_VOUCHERS_SUCCESS: (state, { payload }) => ({
    ...state,
    vouchers: {
      ...state.vouchers,
      requesting: false,
      result: payload.data
    }
  }),
  FETCH_VOUCHERS_FAIL: (state, { payload }) => ({
    ...state,
    vouchers: {
      ...state.vouchers,
      requesting: false,
      error: payload.error
    }
  }),
  EDIT_VOUCHER_REQUEST: (state) => ({
    ...state,
    edit: {
      ...state.edit,
      requesting: true,
      error: null
    }
  }),
  EDIT_VOUCHER_SUCCESS: (state, { payload }) => ({
    ...state,
    edit: {
      ...state.edit,
      requesting: false,
      result: payload.data
    }
  }),
  EDIT_VOUCHER_FAIL: (state, { payload }) => ({
    ...state,
    edit: {
      ...state.edit,
      requesting: false,
      error: payload.error
    }
  }),
  GENERATE_VOUCHERS_REQUEST: (state) => ({
    ...state,
    generate: {
      ...state.generate,
      requesting: true,
      error: null
    }
  }),
  GENERATE_VOUCHERS_SUCCESS: (state, { payload }) => ({
    ...state,
    generate: {
      ...state.generate,
      requesting: false,
      result: payload.data
    }
  }),
  GENERATE_VOUCHERS_FAIL: (state, { payload }) => ({
    ...state,
    generate: {
      ...state.generate,
      requesting: false,
      error: payload.error
    }
  })
}, initialState);

export default voucher;
